<template>
    <div class="v-social">
        <a href="https://vk.com/vetlis"> <img src="@/assets/icons/social/vkontakte.svg" alt="vkontakte"></a>
        <a href="https://t.me/vetlissp"> <img src="@/assets/icons/social/telegram.svg" alt="telegram"></a>
    </div>
</template>
<script>
export default {
    name: 'vSocial'
}
</script>
<style>
    
</style>