<template>
    <div class="v-header-lis">
        <h1 class="v-header_block_subtitle">ЛИС</h1>  
    </div>
</template>
<script>
export default {
    name: 'vHeaderLis'
}
</script>
<style scoped lang="scss">
   .v-header-lis{
    h1{
        font-size: 28px;
        text-align: center;
        line-height: 21px;
        font-family: 'Museo Sans Cyrl';
        font-weight: 700;
        color: #757575;
    }
   } 
</style>