<template>
    <div class="v-vaccination">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Вакцинация</h1> 
                <div class="v-modul-subtitle">В ветеринарной клинике ЛИС вы можете сделать вакцинацию (прививку) своему домашнему питомцу. Вакцинация животных, защищает их от целого ряда распространенных инфекций. Мы используем только самые современные вакцины, поэтому в эффективности и, что не менее важно, безопасности процедуры можете не сомневаться.                                   
                    <picture>
                        <source srcset="../../../../assets/images/vaccination/vac.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/vaccination/vac.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/vaccination/vac.jpg" class="card-img-top r-img" alt="vac">
                    </picture>
                    <h2>В период молочного вскармливания животные получают все необходимые антитела и питательные вещества с молоком матери. Когда молочное кормление прекращается, животные становятся более восприимчивы к заболеваниям, поскольку их иммунная система лишается прежней поддержки. Профилактическая вакцинация способна защитить Вашего питомца от смертельно опасных заболеваний. Вакцинация является гарантией того, что животное будет готово к встрече с потенциально опасными микроорганизмами. Регулярное проведение рассматриваемой нами процедуры предотвращает многие заболевания дыхательной и сердечно-сосудистой систем, а также желудочно-кишечного тракта.</h2>
                    <h2>Вакцинация защищает не только животное, но и его хозяина.</h2>
                    <h2>Вакцинация - превентивная мера, позволяющая организму животного заранее подготовиться к «встрече» с вредоносным микроорганизмом (вирусом, бактерией, спорой грибка) или другими словами метод, позволяющий стимулировать иммунный ответ и создавать иммунитет к возбудителю в отсутствие заболевания.</h2>
                    <h2>Вирус или бактерия является антигеном, т.е. чужеродным, ненужным организму объектом, который он пытается блокировать антителами. У непривитых животных этих антител попросту нет еще в крови, и им необходимо порой значительное время, чтобы их выработать. Причем чем больше возбудителя болезни, тем большее количество антител нужно для его нейтрализации</h2>
                    <h1>Вакцинация кошек</h1>
                    <h2>Вакцинация кошек - является важной и обязательной профилактикой инфекционных заболеваний, опасных не только для животных:</h2>
                    <ul>
                        <li>панлейкопения</li>
                        <li>ринотрахеит</li>
                        <li>кальцевирус</li>
                        <li>хламидиоз и др</li>
                        <li>но и для человека (бешенство).</li>
                    </ul>
                    <h1>Вакцинация собак</h1>
                    <h2>Вакцинация собак – необходимое, ежегодное, профилактическое мероприятие.</h2>
                    <h2>Она защищает от опасных, зачастую смертельных болезней:</h2>
                    <ul>
                        <li>инфекционного гепатита</li>
                        <li>лептоспироза</li>
                        <li>чумы плотоядных</li>
                        <li>парвовирусного энтерита</li>
                        <li>парагриппа</li>
                        <li>бешенства</li>                
                    </ul>
                    <h1>Вакцинация кроликов</h1>
                    <h2>Для предупреждения заболеваний кроликов неизлечимыми болезнями необходимо ежегодно вакцинировать их не только в специализированных хозяйствах, но и на кроличьих фермах, в домашних хозяйствах, а также декоративных пород.</h2>
                    <h2>ВАКЦИНАЦИЯ ПРОТИВ МИКСОМАТОЗА И ВИРУСНОЙ ГЕМОРРАГИЧЕСКОЙ БОЛЕЗНИ (ВГБК)</h2>
                    <h2>Миксоматоз — вирусное заболевание, характеризуется конъюнктивитом, ринитом и появлением на различных участках тела животных студенистых отеков - миксом. Восприимчивы к заболеванию кролики и зайцы всех возрастов. Заражение обычно происходит при укусах кровососущих насекомых (комары, блохи), при прямом контакте с инфицированным животным.</h2>
                    <h2>При классическом течении - инкубационный период укороченный - 2 - 9 дней, смертность 90 - 100%, болезнь длится 5 - 6 дней. Внезапно повышается температура тела до 41,5 - 42°С, через 24 - 48 часов появляется отек головы, шеи, подгрудка, ушей, развивается катаральное, а затем гнойное воспаление конъюнктивы, гнойный блефарит, а также катарально-гнойный ринит, который может переходить в трахеит и бронхит с обильным выделением гнойного экссудата и влажными хрипами при дыхании. Специфического лечения нет.</h2>
                    <h2>При нодулярном (узелковом) течении инкубационный период 7 - 20 дней, смертность до 70%, преимущественно у молодняка. Эта форма не сопровождается повышением температуры тела. На коже спины, ушей, носа, век, на лапах (между пальцами и вокруг ногтей) сначала появляются покраснения в виде пятен или бугорков, а затем узелки. На 10 - 14 день на месте узелков формируются очаги некроза.</h2>
                    <h2>При атипичном течении инкубационный период длительный, смертность до 10 - 15%. Наблюдается воспаление органов дыхания и конъюнктивы. У половозрелых особей сопровождается нарушением воспроизводства. Течение заболевания длительное.</h2>
                    <h2>Вирусная геморрагическая болезнь (ВГБК, некротический гепатит, геморрагическая пневмония) - высококонтагиозное острое заболевание, которое характеризуется кровоизлияниями во всех органах, носовыми кровотечениями и высокой летальностью. Возбудитель ВГБГ очень устойчив в окружающей среде, сохраняется до 100 дней. Заражение происходит при прямом контакте с инфицированным животным, через загрязненные им корма, одежду, хозяйственный инвентарь, через кровососущих насекомых.</h2>
                    <h2>В кролиководческих хозяйствах первыми заболевают взрослые (от 2 лет и старше) хорошо упитанные особи.  Характерна высокая летальность - 90 - 100% и быстрое распространение. Инкубационный период от 6 часов до 4 дней.</h2>
                    <h2>Клинические признаки зависят от течения болезни. Сверхострое течение проявляется внезапно. При нормальной температуре тела и хорошем аппетите у животного появляются судороги и оно погибает. Обычно при внешнем осмотре даже за несколько минут до гибели кролик здоров. Угнетение наблюдают у беременных самок, которые иногда абортируют.
                        Острое течение характеризуется повышением температуры на 1 - 2 градуса, угнетением, отказом от корма, у больных наблюдают кровотечения из носовых ходов, желтоватые или красно-желтые выделения из носа. Через несколько часов после появления выделений из носа наступает смерть. Специфического лечения нет.</h2>
                    <h1>Правила вакцинации:</h1>
                    <ul>
                        <li>За 10-14 дней до вакцинации необходимо провести дегельминтизацию (исключить наличие глистов) любым ветеринарным препаратом. </li>
                        <li>К вакцинации допускаются только животные, не проявляющие клинических признаков заболевания (повышенная температура, отсутствие аппетита, вялость, а так же ослабленные и выздоравливающие животные )</li>
                        <li>Первая вакцинация щенков и котят проводится в возрасте 2-х месяцев ревакцинация через 21 день, дальше ежегодно каждые 12 месяцев.</li>
                    </ul>
                </div>                                                       
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>            
        </div>
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vVaccination',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}
</script>

<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>