<template>
    <div class="v-vomiting-dogs">
        <div class="container">
            <div class="v-modul-block">
                <div class="v-modul-block-left">
                <h1 class="v-modul_title">Рвота у собак</h1>
                <div class="v-modul-subtitle">
                    Рвота у собак — достаточно распространенное явление, с которым сталкиваются многие владельцы. Она может быть вызвана различными причинами, от простого переедания до серьезных заболеваний. Если питомца часто рвет или рвота сопровождается другими симптомами, обязательно обратитесь к ветеринару для профессиональной консультации и лечения.
                <picture>
                    <source srcset="@/assets/images/vetarticles/dog-22.webp" class="card-img-top r-img" type="image/webp">
                    <source srcset="@/assets/images/vetarticles/dog-22.jpg" class="card-img-top r-img" type="image/jpeg"> 
                    <img src="@/assets/images/vetarticles/dog-22.jpg" class="card-img-top r-img" alt="Рвота у собак"> 
                </picture> 
                <!-- <div id="yandex_rtb_R-A-11783043-1"></div> -->
                <h1>Что такое тошнота и рвота</h1>
                <h2>Тошнота и рвота у собак — процессы, связанные с избавлением организма от содержимого желудка через рот.</h2>
                <h2>Тошнота — неприятное ощущение в животе, которое часто предшествует рвоте. Основные признаки: слюнотечение, частое глотание слюны, необычное поведение (беспокойство, нервозность).</h2>
                <h2>Рвота по сути является защитным механизмом, направленным на очищение желудка от инородных предметов или токсинов. Если собаку вырвало, это не всегда является сигналом о нарушении работы желудка и кишечника. Иногда рвота сопровождает серьезные заболевания, например, инфекцию или онкологию. Часто тошнота и рвота являются временными, вызванными неправильным питанием или стрессом. Поэтому важно обратиться к ветеринарному врачу для постановки точного диагноза и подбора эффективного лечения. Не стоит заниматься самолечением и поить животное медикаментами из своей аптечки. Вы рискуете усугубить состояние питомца и смазать клиническую картину.</h2>                                 
                <h2>Также важно отличать рвоту от срыгивания. В первом случае идет непроизвольное выталкивание содержимого желудка. При срыгивании (регургитации) пищевые массы выходят из пищевода. Данный процесс не сопровождается рвотными позывами и в 90% случаев возникает неожиданно. Срыгнутая масса часто содержит кусочки непереваренной еды, слюну. Регургитация может быть нормальным явлением (при быстром заглатывании корма) или признаком болезни (воспаление стенок пищевода).</h2>
                <h1>Возможные причины рвоты у собак</h1>
                <h2>Тошнота и рвота не являются самостоятельными заболеваниями, это признаки сбоя в работе организма. Спровоцировать их могут разные факторы:</h2> 
                    <ul>
                        <li>переедание, неподходящий корм, инородные тела в желудке или кишечнике;</li>
                        <li>воспалительные заболевания желудка или кишечника: гастрит, энтерит, колит, язва, панкреатит, гепатит, холецистит (основная причина — неправильное питание животного);</li>
                        <li>отравление бытовой химией, медикаментами, удобрениями, инсектицидами (владельцы должны помнить, что ядом для собак могут быть привычные для человека продукты: виноград, лук, шоколад, изюм);</li>
                        <li>инфекционные патологии, вызванные бактериями, вирусами, грибками или простейшими: сальмонеллез, лептоспироз, парвовирусный энтерит, коронавирусный энтерит, кокцидиоз, гиардиоз;</li>
                        <li>паразитарные болезни, вызванные гельминтами: аскариды, токсокары, анкилостомы, трихинеллы, эхинококки, дипилидии, или эктопаразитами: блохи, клещи, вши;</li>
                        <li>инвангинация (попадание одной части кишечника в другую происходит при серьезном воспалении ЖКТ, онкологии);</li>
                        <li>ЧМТ (если после удара головой собаку сильно рвет, нужно срочно посетить клинику);</li>
                        <li>аллергические реакции на пищу, лекарства, укусы насекомых, пыльцу, пыль;</li>
                        <li>новообразования (язва желудка и кишечника развивается при бесконтрольном и длительном приеме определенных лекарств).</li>
                    </ul>
                <h2>Причины, почему собаку тошнит, не связанные с желудочно-кишечным трактом:</h2>
                        <ul>
                            <li>заболевания почек: нефрит, нефроз, почечная недостаточность, почечные камни;</li>
                            <li>патологии печени: цирроз, гепатоз, портосистемная шунтирование;</li>
                            <li>болезни сердца: сердечная недостаточность, кардиомиопатия, врожденные пороки;</li>
                            <li>заболевания легких: пневмония, бронхит, астма, плеврит;</li>
                            <li>патологии головного мозга: менингит, энцефалит, гидроцефалия, опухоли, травмы, судороги;</li>
                            <li>гормональные нарушения: гипертиреоз, гипотиреоз, диабет;</li>
                            <li>психогенные факторы: стресс, страх, тревога, раздражение</li>
                        </ul>
                <h2>Также причиной тошноты у собаки может быть укачивание, токсикоз у беременных самок, резкая смена питания, переедание, голодание, тепловой удар, прием медикаментов, раздражающих слизистую. Все эти факторы считаются неопасными для жизни питомца, но все же требуют профилактического осмотра у ветврача.</h2>
                <h2>Иногда рвота возникает из-за сильного кашля, когда приступы затяжные или сопровождаются сильным сокращением мускулатуры. Подобная симптоматика характерна для хронических недугов сердца, астмы, бронхита, отека легких. Как отличить кашель от рвотных позывов? Кашель обычно возникает неожиданно, без предварительной тревожности, отсутствуют глотательные движения и сильное слюнотечение. Но если приступы очень сильные, то они часто заканчиваются рвотой.</h2>
               
                <h1>Виды рвоты у собак</h1>
                <h2>Рвота у собак отличается по характеру, частоте, времени, объему и составу. В зависимости от этих параметров выделяют следующие виды:</h2>
                    <ul>
                        <li>Однократная или повторяющаяся. Однократная обычно не является опасной и связана с перееданием, непереваренной пищей, глотанием воздуха или волос. Повторяющаяся указывает на серьезное заболевание и требует обращения к ветеринару</li>
                        <li>Острая или хроническая. В первом случае рвота возникает внезапно и длится не более двух недель. Основные причины: инфекция, токсикоз, аллергия, проглатывание инородного тела или опухоль. Хроническая рвота длится более 14 дней и связана с воспалительными, паразитарными, гормональными или другими хроническими патологиями;</li>
                        <li>Утренняя или вечерняя. Утренняя происходит на голодный желудок, причины: гастрит, язва, панкреатит или гипертиреоз. Вечерняя происходит после приема пищи и вызвана перееданием, неподходящим кормом, инородным телом, опухолью.</li>
                    </ul>
                <h2>Что делать, если собаку вырвало? Для начала нужно понять, опасна или нет рвота для животного? Следует обратить внимание на содержимое рвотных масс:</h2>
                    <ul>
                        <li>С кровью. Свидетельствует о повреждении слизистой оболочки желудка или кишечника, нарушении свертываемости крови, внутреннем кровотечении или опухолевом процессе. Кровь в рвоте бывает свежей (ярко-красной) или частично переваренной (темно-коричневой, похожей на кофейную гущу);</li>
                        <li>С желчью. Желтая или зеленая жидкость, вырабатываемая печенью и содержащаяся в желчном пузыре, помогает переваривать жиры в кишечнике. Рвота с желчью связана с заболеваниями печени, желчного пузыря, поджелудочной железы;</li>
                        <li>С белой пеной. Рвотные массы не содержат остатков пищи, состоят из слизи в виде пены и жидкости. Пена — это слизь, которую вырабатывают стенки желудка, жидкость — желудочный сок. Причиной такой рвоты может быть пропуск приема пищи, если животное привыкло питаться по определенному графику. Также она характерна для гастрита, язвы, панкреатита.;</li>
                        <li>Со слизью. Вязкая жидкость, вырабатываемая слизистой оболочкой желудка и кишечника, защищает ее от раздражения и повреждения. Рвота со слизью свидетельствует о воспалительных болезнях желудка или кишечника;</li>
                        <li>Зеленого цвета. Наличие фрагментов травы свидетельствует о том, что угрозы для питомца нет. Но все же стоит показать собаку специалисту, поскольку поедание зелени может быть причиной проблем с пищеварением. При появлении дополнительных симптомов, например, судорог или диареи, есть вероятность отравления пса ядовитыми растениями;</li>
                        <li>Розового цвета. Возникает из-за наличия небольшого количества крови, что характерно для незначительных повреждений или воспалительных процессах в пищеводе.</li>
                    </ul>
                <h2>Наличие в рвотных массах гельминтов или их частиц свидетельствует о сильном заражении паразитами. В таких ситуациях самостоятельно глистогонить пса не рекомендуется, поскольку большое количество убитых глистов повышает риск интоксикации и непроходимости ЖКТ. Дегельминтизацию должен проводить опытный врач в клинике.</h2>                
                <h1>Другие симптомы при рвоте у собак</h1>
                <h2>При патологических процессах у животного кроме рвоты будут и иные проявления. Например, для панкреатита характерны сильные боли, которые очень сильно пугают владельца. Ведь питомец начинает скулить, выгибать спину, падать на передние лапы.</h2>
                <h2>Попадание в организм инородного тела проявляется по-разному, в чем и заключается его главная опасность. Частичная закупорка желудочно-кишечного тракта сопровождается временным отказом от еды и воды. Иные симптомы плохого самочувствия, за исключением рвоты, отсутствуют. Интоксикация при отравлении проявляется апатией, потерей аппетита и неврологическими признаками.</h2>
                <h2>Травмы пищевода и ЖКТ приводят к появлению крови в рвотных массах и дегтеобразным фекалиям. При инфекционных патологиях основное проявление — лихорадка.</h2>
                <h2>Неопасные причины, не сопровождающиеся осложнениями, не приводят к существенным изменениям в состоянии собаки. При частой рвоте и сильной тошноте пес может отказываться от еды, также наблюдается падение активности.</h2>
                <h2>Симптомы, требующие незамедлительного осмотра у врача:</h2>
                    <ul>
                        <li>инородные частицы в каловых или рвотных массах;</li>
                        <li>большое количество крови в кале;</li>
                        <li>кровавая или темно-коричневая рвота, напоминающая кофейную гущу;</li>
                        <li>подозрение на то, что питомец съел медикаменты, бытовые химикаты или иные токсины;</li>
                        <li>неврологические проявления: спазмы, неестественное положение тела, подгибание лап.</li>
                    </ul>               
                <h1>Осложнения рвоты у собак</h1>
                <h2>Рвота приводит к различным осложнениям, угрожающим жизни животного:</h2>
                   <ul>
                        <li>Обезвоживание. Потеря жидкости и электролитов из организма в результате рвоты и поноса. Приводит к нарушению работы сердца, почек, мозга. Признаки обезвоживания: сухость слизистых, увеличение частоты пульса и дыхания, снижение тургора кожи, вялость, апатия, шок;</li>
                        <li>Ацидоз. Нарушение кислотно-основного баланса в крови в сторону повышения кислотности. Вызван потерей щелочи при рвоте, нарушением работы почек, дыхательной системы, печени. Симптомы ацидоза: слабость, тахикардия, одышка, судороги, кома;</li>
                        <li>Гипогликемия. Снижение уровня глюкозы в крови ниже нормы. Вызвана недостаточным поступлением глюкозы из пищи при рвоте и поносе, повышенным расходом глюкозы при инфекции, токсикозе, стрессе, а также нарушением выработки или действия инсулина при диабете, опухолях поджелудочной железы. Основные проявления: слабость, дрожание, сонливость, судороги, потеря сознания, кома;</li>
                        <li>Аспирационная пневмония. Воспаление легких, вызванное попаданием рвотных масс в дыхательные пути. Причина: неправильная техника введения лекарств или пищи через зонд, нарушение рефлекса закрытия гортани при рвоте, паралич или слабость глотательных мышц при некоторых заболеваниях. Признаки: кашель, хрипы, одышка, лихорадка, выделения из носа и рта.</li>
                   </ul>
                <h1>Что дать собаке от рвоты в домашних условиях</h1>
                <h2>Если рвота не сопровождается другими тревожными симптомами, и вы уверены, что она не связана с серьезным заболеванием, то попробуйте помочь питомцу в домашних условиях.</h2>
                <h2>Можно дать собаке некоторые препараты, которые помогут снять тошноту, улучшить пищеварение и защитить слизистую желудка. Но перед этим обязательно проконсультируйтесь с ветеринаром, так как некоторые медикаменты противопоказаны при определенных заболеваниях или имеют побочные эффекты.</h2>
                <h2>При многократной рвоте или сильной тошноте, когда пес не может есть и пить, ему можно дать сироп «Домперидон» или поставить укол («Серения», «Ондансетрон»). Применение инъекций считается оптимальным вариантом, поскольку заставить собаку съесть таблетку или выпить сироп очень сложно. Лекарства на основе метоклопрамида и домперидона усиливают перистальтику (сокращение стенок желудка), поэтому их запрещается применять при закупорке желудочно-кишечного тракта.</h2>
                <h2>Как помочь собаке при рвоте? При укачивании или однократном приступе ничего делать не надо. Если рвота повторяется, стоит ограничить животное в еде на 4-12 часов, особенно при повторении рвотных позывов после приема пищи. Поить животное нужно часто, но небольшими порциями.</h2>
                <h2>Однако, если рвота многократная и позволяет питомцу нормально пить и есть, а обратиться в клинику нет возможности, сделайте собаке укол. Применение инъекционных препаратов от тошноты для собак требует от владельцев навыков выполнения подкожных инъекций. Укол обычно ставят в холку или в области лопаток.</h2>
                <h2>Допускается применение «Но-Шпы» или «Смекты» для устранения спазмов и ускорения выхода токсинов. Некоторые владельцы дают животному пробиотики, например, «Лактоферон», но нужно понимать, что данная группа препаратов никак не влияет на тошноту и рвоту. Они воздействуют только на бактерии, находящиеся в кишечнике.</h2>
                <h2>Если остановить рвоту у собаки не удается в течение 24 часов, или она сопровождается другими симптомами, то необходимо обратиться к ветеринарному врачу для диагностики и лечения. Признаки, которые должны насторожить хозяина:</h2>
                    <ul>
                        <li>понос;</li>
                        <li>лихорадка;</li>
                        <li>апатия;</li>
                        <li>ухудшение самочувствия;</li>
                        <li>отказ от еды в течение двух дней и более;</li>
                        <li>кровотечение;</li>
                        <li>дыхательные или сердечные нарушения.</li>
                    </ul>
                <h2>Также необходимо срочно посетить клинику, если подозреваете, что питомец мог проглотить инородное тело, токсин или если у него есть хронические заболевания, которые могут быть связаны с рвотой.</h2>
                <h1>Диагностика</h1>
                <h2>Для выявления причины, почему собака блюет, ветеринарный врач проведет осмотр животного, соберет анамнез и назначит необходимые исследования. В зависимости от подозреваемого диагноза требуются следующие исследования:</h2>
                    <ul>
                        <li>Общий анализ крови. Оценка количества и качества кровяных клеток (эритроцитов, лейкоцитов, тромбоцитов), гемоглобина, гематокрита. Помогает выявить анемию, воспаление, инфекцию, нарушение свертываемости крови;</li>
                        <li>Биохимический анализ крови. Важен для оценки работы различных органов и систем: печени, почек, поджелудочной железы, а также уровень глюкозы, белка, электролитов;</li>
                        <li>Анализ мочи. Требуется для оценки состояния почек, мочевыводящих путей;</li>
                        <li>Анализ фекалий. Позволяет оценить состояние кишечника, наличие крови, слизи, жира, паразитов, бактерий, вирусов и других веществ в фекалиях;</li>
                        <li>Рентгенография. Получение изображения внутренних органов собаки на специальной пленке или экране. Помогает выявить инородные тела, опухоли, камни, перфорации, инвагинации;</li>
                        <li>Ультразвуковое исследование. Направлено на выявление воспаления, опухоли, камней, кист, абсцессов;</li>
                        <li>Эндоскопия. Позволяет осмотреть слизистую оболочку желудка и кишечника с помощью специальной гибкой трубки с камерой и светом на конце, которая вводится через рот или анус собаки. Также с помощью эндоскопии можно провести биопсию, то есть взять маленький кусочек ткани для микроскопического исследования, или удалить инородное тело;</li>
                        <li>Компьютерная томография. Позволяет получить трехмерное изображение внутренних органов собаки на экране с помощью рентгеновских лучей и компьютерной обработки. Помогает выявить опухоли, кровоизлияние.</li>
                    </ul>
                <h1>Лечение тошноты и рвоты у собак</h1>
                <h2>Лечение рвоты у собаки зависит от причины, которая вызвала рвоту. Только ветеринарный врач может поставить точный диагноз и назначить адекватную терапию. В общем случае, используются следующие терапевтические методы:</h2>
                    <ul>
                        <li>Инфузионная терапия. Введение жидкости и электролитов в вену или подкожно с помощью капельницы или шприца. Восстанавливает водно-солевой баланс, снижает обезвоживание, ацидоз, гипогликемию;</li>
                        <li>Антиэметическая терапия. Применение препаратов, подавляющих рвотный рефлекс и уменьшающих тошноту. Снижает частоту и интенсивность рвоты, улучшает аппетит и самочувствие собаки. Доза и способ введения препаратов рассчитываются индивидуально в зависимости от веса животного и тяжести состояния;</li>
                        <li>Этиотропная терапия. Лечение, направленное на устранение причины рвоты. Включает такие меры, как антибиотикотерапия, противопаразитарная терапия, противоопухолевая терапия, хирургическое вмешательство, диетотерапия. Выбирается ветеринарным врачом в зависимости от диагноза и состояния пса.</li>
                    </ul>
                <h2>При опухолях и инвагинации требуется хирургическое вмешательство. При ЧМТ рекомендуется оставить питомца в стационаре и пройти обследование у невролога. Если состояние собаки тяжелое ветврач принимает решение о госпитализации.</h2>
                <h1>Как кормить питомца при рвоте</h1>
                <h2>Питание собаки при рвоте должно быть легкоусвояемым, нежирным, некислым и неострым. Кормить нужно часто, но маленькими порциями, чтобы не перегружать желудок и кишечник. Рацион животного рекомендуется согласовать с ветеринаром, который подберет оптимальный вариант в зависимости от причины рвоты и состояния собаки.</h2>
                <h2>Предоставьте псу доступ к чистой свежей воде, но не давайте пить слишком много за раз, чтобы не вызвать нового приступа рвоты. Остановитесь на небольших порциях, но поите как можно чаще. Добавьте в воду немного сахара или меда, чтобы предотвратить гипогликемию или немного соды, чтобы нейтрализовать избыток кислоты в желудке.</h2>
                <h2>Приостановите кормление на 4-12 часов, в зависимости от тяжести рвоты. Это даст возможность желудку и кишечнику отдохнуть и восстановиться. Не давайте кости, жирную, острую, соленую или сладкую пищу, а также любые продукты, на которые может быть аллергия или непереносимость.</h2>
                <h2>После голодной диеты начните кормить небольшими порциями легкоусвояемого корма. Это может быть специальный ветеринарный продукт для собак с чувствительным пищеварением, или домашняя диета из отварного филе курицы или индейки и риса в соотношении 1:2. Постепенно увеличивайте количество и интервалы кормления, а затем возвращайтесь к обычному рациону.</h2>
                <h1>Рвота у щенка</h1>
                <h2>Щенки отличаются повышенной активностью и с интересом познают окружающий мир, что часто приводит к проглатыванию посторонних предметов. Их иммунитет еще не до конца сформирован. Поэтому, если у щенка рвота не проходит в течение 12 часов или сопровождается другими тревожными симптомами, необходимо немедленно обратиться к ветеринарному врачу.</h2>
                <h2>Также стоит знать еще несколько нюансов:</h2>
                    <ul>
                        <li>Если рвота сопровождается поносом, то малыш быстро теряет жидкость, протеины и электролиты, особенно при отказе от еды;</li>
                        <li>Из-за рвоты и голодания часто развивается гипогликемия (особенно у представителей миниатюрных пород). Резкое снижение уровня глюкозы в крови приводит к обморокам, судорогам и даже летальному исходу;</li>
                        <li>Щенки более взрослых особей подвержены инфекционным болезням и переносят их намного тяжелее;</li>
                        <li>Ветеринары не советуют применять к малышам голодную диету.</li>
                    </ul>
                <h1>Профилактика</h1>
                <h2>Чтобы предотвратить рвоту у собаки, необходимо соблюдать следующие меры профилактики:</h2>
                   <ul>
                    <li>Правильно кормить собаку, выбирая качественный корм, подходящий по возрасту, породе, весу и состоянию здоровья. Не давать питомцу кости, жирную, острую, соленую или сладкую пищу;</li>
                    <li>Регулярно проводить дегельминтизацию, профилактику от эктопаразитов (блохи, клещи, вши). Для этого необходимо использовать специальные препараты, которые можно купить в ветеринарной аптеке или клинике по рекомендации ветеринарного врача. Дегельминтизацию проводят не реже, чем раз в три месяца, а обработку от эктопаразитов — раз в месяц;</li>
                    <li>Регулярно ставить прививки от опасных инфекционных заболеваний: бешенство, чума, парвовирусный гастроэнтерит, лептоспироз. Вакцинация помогает защитить собаку от заражения и развития тяжелых осложнений, которые могут быть связаны с рвотой. Она должна проводиться согласно графику, устанавливаемому ветеринарным врачом.;</li>
                    <li>Не оставлять в свободном доступе бытовую химию, опасные растения, лекарственные средства;</li>
                    <li>Соблюдать гигиену, поддерживать чистоту и порядок в месте проживания питомца, регулярно ухаживать за шерстью, кожей, глазами, ушами, зубами и когтями. Это помогает предотвратить попадание в организм вредных микроорганизмов, паразитов, токсинов. Гигиена позволяет своевременно обнаружить любые повреждения, воспаления, опухоли на коже и слизистых.</li>
                   </ul>
                   <h2>Контролируйте активность и поведение питомца, не допускайте, чтобы собака бегала без присмотра по улице, грызла и глотала различные предметы, ела с земли или из мусорных баков, контактировала с больными или неизвестными животными. Соблюдение простых правил помогает избежать травм, отравлений, инфекций и стресса. Также это позволяет воспитать пса, улучшить его социализацию и обучение.</h2>
                </div>
                </div> 
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>          
        </div>
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
// import { onMounted } from 'vue'

export default {
    name: 'vVomitingDogs',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize],
//     setup(){
//         onMounted(()=>{
//             window.yaContextCb.push(() => {
//             Ya.Context.AdvManager.render({
//             "blockId": "R-A-11783043-1",
//             "renderTo": "yandex_rtb_R-A-11783043-1"
//     })
// })
//         })
//     }
}
</script>
<style scoped lang="scss">
 @import '@/assets/css/modul.scss';
</style>
