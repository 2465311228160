<template>
    <div class="v-header-vet">
        <h4 class="v-header_block_title">Ветеринарная клиника</h4>  
    </div>
</template>
<script>
export default {
    name: 'vHeaderVet'
}
</script>
<style scoped lang="scss">
     .v-header_block_title{
        margin-left: 10px;
        font-size: 22px;
        font-weight: 300;
        text-align: center;
        line-height: 21px;
        font-family: 'Museo Sans Cyrl';
        font-weight: 300;
        color: #757575;;
    }
</style>