import { render, staticRenderFns } from "./v-vaccination.vue?vue&type=template&id=4690fdbe&scoped=true"
import script from "./v-vaccination.vue?vue&type=script&lang=js"
export * from "./v-vaccination.vue?vue&type=script&lang=js"
import style0 from "./v-vaccination.vue?vue&type=style&index=0&id=4690fdbe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4690fdbe",
  null
  
)

export default component.exports