<template>
    <div class="v-infection">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Инфекционные заболевания</h1> 
                <div class="v-modul-subtitle">
                    <h2>Появление вирусных заболеваний у наших любимых четвероногих друзей, собак и кошек, это закономерный результат попадания внутрь патогенной флоры, которая тут же начинает размножаться и постепенно рассеиваться по их организму.</h2>
                    <h2>Вирусы могут передаваться от одной особи ко многим другим, причём часто случается, что кот или собака не болеют, а лишь являются скрытыми передатчиками инфекции и многократно заражают ею других.</h2>
                    <picture>
                        <source srcset="../../../../assets/images/therapy/infection.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/therapy/infection.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/therapy/infection.jpg" class="card-img-top r-img" alt="infection">
                    </picture>
                    <h1>Причины заражения</h1>
                    <h2>Чаще всего причиной заражения является ослабленный иммунитет четвероногого, который может снизиться по ряду причин:</h2>
                    <ul>
                        <li>стресс</li>
                        <li>перенесенное ранее заболевание</li>
                        <li>переохлаждение</li>
                        <li>наличие внутренних и внешних паразитов</li>
                        <li>неправильное питание</li>
                        <li>ненадлежащее содержание</li>
                        <li>возраст</li>
                        <li>наличие в анамнезе аутоиммунных заболеваний и т.д.</li>
                    </ul>
                    <h2>Пути передачи вируса:</h2>
                    <ul>
                        <li>через прямой телесный контакт – укусы, обнюхивание, игры. Даже просто нахождение рядом с больным животным чревато инфицированием здорового: все помним о воздушно-капельной передаче инфекций</li>
                        <li>через пищу. Поэтому не стоит кормить здоровое животное из одной миски с заболевшим.</li>
                        <li>через выделения, экскременты – в них долгое время продолжают содержаться активные вирусы.</li>
                        <li>из-за пониженного иммунитета, который более всего характерен для некоторых декоративных пород, совсем маленьких щенков и котят, пожилых кошек и собак</li>
                        <li>от клещей, блох и грызунов – ведь они являются переносчиками некоторых вирусов</li>
                    </ul>
                    <h1>Распространенные вирусные инфекции</h1>
                    <h1>Бешенство</h1>
                    <h2>Опасное заболевание, которое не поддается лечению. Причиной заражения, как правило, становится контакт с зараженным животным, а именно – обмен слюной, кровью, продуктами жизнедеятельности. Более того, бешенство может передаваться от питомца к человеку. Надежный способ защиты от вируса – ежегодная профилактическая прививка.</h2>
                    <h1>Ринотрахеит</h1>
                    <h2>Кашель бывает не только у людей: он может появиться и у животных. Не придавать этому значения нельзя: ринотрахеит – это поражение дыхательных путей, которое способно иметь опасные последствия. </h2>
                    <h2>Если вы заметили повышенную температуру у своей кошки, обильные жидкие истечения из носа или глаз, хрипы, если животное чихает и ему сложно глотать – вероятнее всего, это ринотрахеит</h2>
                    <h2>Как правило, при своевременно назначенном верном лечении ринотрахеит проходит за две недели, но есть риск его трансформации в хроническую форму. Тогда животное будет страдать от кератита и бронхопневмонии, а также может ослепнуть</h2>
                    <h2>Передаётся ринотрахеит по воздуху. У переболевшей кошки появляется иммунитет, однако какое-то время после болезни она остаётся заразной. Лучшая профилактика ринотрахеита – это хороший уход за питомцем и своевременная вакцинация.</h2>
                    <h1>Кальцивироз</h1>
                    <h2>Этот вирус попадает в почки, мозг и слизистые оболочки в пасти животных, обычно – котят. Во рту и в носу появляются язвы, повышается температура у кошки, начинается кашель, пропадает аппетит. </h2>
                    <h2>Без правильного лечения у животного может развиться пневмония. Котята часто погибают от кальцивироза, поэтому обязательно вовремя сделать животному прививку, посещать ветеринарную клинику для профилактических осмотров</h2>
                    <h1>Панлейкопения кошек</h1>
                    <h2>Эту болезнь также называют чумкой. Она наносит существенный вред костному мозгу, лимфатической системе, кишечнику. </h2>
                    <h2>Заразиться панлейкопенией можно через личный контакт, вещи, укусы насекомых. Котята, заразившиеся панлейкопенией, часто гибнут. Взрослые особи выживают при вовремя начатом лечении. </h2>
                    <h2>Высокая температура у кота, отсутствие аппетита и жажды, понос, рвота, слабость – всё это симптомы панлейкопении. А если при этом животное старается спрятаться в самый дальний и укромный уголок дома – пора срочно везти его к ветеринару. 
                        Он сделает клинический анализ крови коту и, обнаружив пониженное содержание лейкоцитов, поставит диагноз и сразу назначит лечение. Переболевшая чумкой кошка приобретает пожизненный иммунитет к этой болезни.</h2>
                    <h1>Коронавирусные инфекции</h1>
                    <h2>Коронавирус поражает кишечник, вследствие чего начинается рвота и диарея у кота. Некоторые собаки и кошки погибают от этой инфекции, но большинство выздоравливает при вовремя начатом лечении – главное, не допустить обезвоживания. </h2>
                    <h2>Бывает, что болезнь переходит в затяжную форму, когда у животного нет никаких особых проявлений, кроме редких поносов. Однако такое животное навсегда остаётся переносчиком коронавируса, которым можно заразиться через фекалии.</h2>
                    <h1>Вирусный иммунодефицит кошек</h1>
                    <h2>Вирусный иммунодефицит кошек (FIV или ВИК) – это преимущественно скрыто протекающая инфекция, которая характеризуется поражением иммунной системы. Она вызывается ретровирусом, по своему строению и биохимии он сходен с вирусом СПИДа человека.</h2>
                    <h2>Чаще всего вирус встречается у взрослых и старых кошек (от 5 лет), которые свободно гуляют на улице. Причем чаще болеют коты, чем кошки. Особенно склонны к заражению животные, охраняющие свою территорию. Вирус встречается у 1-3% клинически здоровых животных и у 9-15% кошек с клиническими признаками нарушения в иммунной системе. Вирус находят в крови, слюне, моче и других жидкостях тела животного. Основной путь передачи – парентеральный (через укусы), реже вирус передается трансплацентарно, в процессе родов и с молоком от кошки-матери котятам</h2>
                    <picture>
                        <source srcset="../../../../assets/images/therapy/infection4.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/therapy/infection4.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/therapy/infection4.jpg" class="card-img-top r-img" alt="infection">
                    </picture>
                    <h2>В слюне зараженных кошек содержится максимальное количество вируса.</h2>
                    <h2>Вирус неустойчив во внешней среде и разрушается под действием солнечного излучения и при высушивании, поэтому распространение его в группе животных происходит только при прямом и тесном контакте.</h2>
                    <h1>Вирус лейкемии кошек</h1>
                    <h2>Этот вирус поражает кровеносную и лимфатическую систему. Происходит значительное увеличение лейкоцитов в крови, появляются злокачественные новообразования. Лейкемия у кошек может долгое время протекать бессимптомно – животное уже является носителем вируса, но никаких признаков заболевания нет.</h2>
                    <h2>Необъяснимая потеря веса, слабость и быстрая утомляемость, проблемы с мочеиспусканием должны насторожить любого хозяина и заставить немедленно показать животное ветеринару. Врач при пальпации обнаружит увеличение лимфоузлов и печени, поставит диагноз и назначит адекватное лечение.</h2>
                    <h1>Парвовируса собак</h1>
                    <h2>Наиболее распространённой является кишечная форма парвовируса собак. Она характеризуется рвотой, поносом, отсутствием аппетита. 
                        Сердечная форма парвовируса встречается гораздо реже – благодаря сознательности хозяев, вовремя вакцинирующих своих питомцев. Кишечный парвовирус вызывает обезвоживание организма, боль в желудке у собаки, тахикардию. 
                        Чтобы поставить диагноз, необходимо сделать УЗИ органов ЖКТ и УЗИ сердца собаке, взять анализы крови и мочи. Профилактикой парвовируса является своевременная вакцинация по правильной схеме, которую вам распишет ветеринар.</h2>
                    <h1>Чума плотоядных</h1>
                    <h2>Этому заболеванию подвержены собаки и дикие животные – волки, лисы. Чумка чаще встречается у щенков и пожилых собак из-за более слабого иммунитета. </h2> 
                    <h2>Начинается она остро – высокая температура, до 41 ºС, истечения у собаки из носа, вялость. Далее температура немного снижается, появляется озноб, затруднённое дыхание, на коже лап возникает сыпь. Собаке неприятен яркий свет, она старается от него спрятаться в укромных уголках дома. 
                        При остром течении чумки может развиться кома, ведущая к гибели собаки. При появлении грозных признаков чумы плотоядных необходимо как можно быстрее транспортировать животное в ветеринарную клинику. Профилактикой чумки являются своевременно сделанные прививки.</h2> 
                    <h1>Вирус парагриппа</h1> 
                    <h2>Сильный кашель у собаки, повышение температуры – это симптомы парагриппа, поражающего дыхательные пути. Заболевание лечится несложно, однако важно делать это под контролем ветеринара, поскольку к парагриппу нередко присоединяются бактериальные и грибковые инфекции. Если собака начала сильно кашлять и хрипеть – отвезите её к врачу.</h2> 
                    <h1>Аденовирус</h1>   
                    <h2>Заразиться животное может воздушно-капельным путём. Если вы услышали кашель у собаки, заметили снижение аппетита, насморк – следует показать её ветеринару. Ощупав лимфоузлы и прослушав лёгкие, он поставит диагноз. Аденовирус может принимать форму гепатита, что очень опасно для жизни вашего питомца. Не забывайте о профилактических прививках и периодических осмотрах у врача.</h2>
                    <h2>Часто встречается в питомниках, поэтому его народное название – «питомниковый кашель». Болезнь характерна только для собак и хорьков – кошек и людей не поражает. При аденовирусе наблюдаются симптомы респираторных заболеваний – ларинготрахеита, фарингита, бронхита.</h2>
                    <h1>Энтерит</h1>
                    <h2>Болезнь характеризуется воспалительным процессом слизистой оболочки тонкого кишечника. Особенно подвержены щенки. Протекает остро, приводит к интоксикации и поражением других внутренних органов. Основной симптом – диарея, которая сопровождается неприятным запахом. Может проявляться кишечной или сердечной формой. Энтерит поддается лечению, однако защитой от него является профилактическая мера – вакцинация.</h2>
                    <h1>Бактериальные инфекции</h1>
                    <h2>Бактерии – это одноклеточные микроорганизмы, размножающиеся делением. Самые известные – стафилококки, стрептококки, спирохеты и другие. К счастью, наука шагнула далеко вперед, и еще в девятнадцатом веке была открыта группа веществ, способная эффективно бороться с большинством видов бактерий. Мы знаем их под словом «антибиотик». Активно применяются и в ветеринарной медицине.</h2>
                    <picture>
                        <source srcset="../../../../assets/images/therapy/infection2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/therapy/infection2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/therapy/infection2.jpg" class="card-img-top r-img" alt="infection">
                    </picture>
                    <h1>Бруцеллез</h1>
                    <h2>Встречается нечасто, однако приводит к серьезным последствиям. Основной путь заражения – употребление термически не обработанной пищи. Бактерии-бруцеллы накапливаются в крови и лимфатических узлах, вызывая специфические симптомы, связанные с патологиями суставов и органов репродуктивной системы.</h2>
                    <h1>Бордетеллез</h1>
                    <h2>Болезнь передается воздушно-капельным путем. Бактерии вызывают у собаки приступообразный кашель – трахеобронхит. При несвоевременном лечении у четвероногого может развиться пневмония. Основные признаки – вялость, отказ от игр, ухудшение аппетита, повышение температуры тела, выделения из глаз и носа.</h2>
                    <h1>Лептоспироз</h1>
                    <h2>Переносчики – грызуны и переболевшие четвероногие. Животные, которые перенесли лептоспироз, приобретают иммунитет к нему, однако сами становятся носителями бактерии. Имеет несколько стадий – изменение состояния любимца может указывать на заражение. Лептоспироз передается от животного к человеку. Основная профилактическая мера – вакцинация.</h2>
                    <h1>Туляремия</h1>
                    <h2>Переносчиками являются грызуны, в некоторых случаях – иксодовые клещи, блохи. Бактерия-возбудитель устойчива к условиям внешней среды. Инкубационный период туляремии длится от нескольких суток до нескольких недель. Симптомы – повышение температуры, диарея, увеличение лимфатических узлов, выделения из носа и глаз</h2>
                    <picture>
                        <source srcset="../../../../assets/images/therapy/infection3.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/therapy/infection3.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/therapy/infection3.jpg" class="card-img-top r-img" alt="infection">
                    </picture>
                    <h1>Столбняк</h1>
                    <h2>Заболевание, которое способно нанести вред не только человеку, но и домашнему животному. Мы с детства помним, что заражение столбняком происходит при попадании бактерии в открытую глубокую рану. Правда, в отличие от людей, собак и кошек не прививают. К счастью, у питомцев столбняк встречается редко. И все же в качестве профилактической меры рекомендуется тщательно обрабатывать любое ранение, полученное четвероногим</h2>
                    <h1>Ботулизм</h1>
                    <h2>Опасное заболевание, причем как для людей, так и для домашних животных. Вызывается бактерией Clostridium botulinum при попадании в организм (как правило, путем употребления некачественных продуктов питания). Поражает центральную нервную систему. Трудно поддается лечению – без своевременно оказанной помощи четвероногий может погибнуть.</h2>
                    <h1>Листериоз</h1>
                    <h2>Путь заражения – через слизистые оболочки. При листериозе наблюдаются ухудшение аппетита, потеря ориентации в пространстве, обильное слюноотделение и даже паралич. При выявлении болезни на ранней стадии выживаемость высока.</h2>
                    <h1>Диагностика и лечение</h1>
                    <h2>Диагностика инфекционных заболеваний зависит от клинических признаков. Иногда ветеринарному врачу достаточно осмотра для того, чтобы заподозрить ту или иную болезнь. Однако в ряде случаев требуются результаты исследований – анализов мочи, крови, кала, УЗИ, и т.д.</h2>
                </div>                                        
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>            
        </div> 
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vInfection',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}
</script>
<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>