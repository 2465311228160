<template>
    <div class="v-rehabilitation">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Период реабилитации</h1> 
                <div class="v-modul-subtitle">Предлагаемые рекомендации являются общими, т.е. разработаны для большинства пациентов.
                    В случаях индивидуального течения восстановительного периода, врач может предложить индивидуальную схему лечения и график осмотров пациента. 
                    Уточняйте, пожалуйста, рекомендации при каждом посещении врача.
                    <picture>
                        <source srcset="../../../assets/images/recommendations/pos3.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/recommendations/pos3.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/recommendations/pos3.jpg" class="card-img-top r-img" alt="Реабилитация">
                    </picture>
                    <h1>Режим</h1>
                    <h2>В восстановительный период соблюдение режима может иметь определенное лечебное значение.
                        Лечащий врач дает рекомендации, как вести себя в послеоперационный период.
                        Очень важно соблюдать все назначения вашего врача, принимать рекомендуемые препараты.</h2>
                        <h2>Если у вас возникают сложности с выполнением всех рекомендаций, обязательно сообщите об этом врачу для коррекции лечения.</h2>
                        <h1>Питание в восстановительный период</h1>
                        <h2>Для поддержания здоровья в период восстановления или после перенесенной операции (заболевания, травмы) целесообразно кормить животных кормами, специально созданными для восполнения питательных веществ в период болезни, интенсивной терапии.</h2>
                        <h1>Гигиена</h1>
                        <h2>После операции не следует купать животных. Если загрязнение слишком сильное, лучше воспользоваться влажными салфетками для ухода за животными.</h2>
                        <h1>Уход за раной в послеоперационный период</h1>
                        <h2>После операции на животное одевают послеоперационную попону для того, чтобы защитить рану от разлизывания и случайного загрязнения.
                            Попону не следует снимать в течение 10-12 дней: 8-10 дней до снятия швов и еще 2 дня после снятия швов.</h2>
                        <h2>Обязательно посещайте врача в назначенный день для коррекции лечения!</h2>    
                </div>                                                       
                </div> 
                <v-contacts v-if="small"/>               
                <v-side v-else/>
            </div>
             
        </div>
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vRehabilitation',
    components: {
        vSide,
        vContacts
    }, 
    mixins: [resize]
}

</script>
<style scoped lang="scss">

 @import '@/assets/css/modul.scss';

</style>