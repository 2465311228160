<template>
    <div class="v-pancreas">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Болезни печени и поджелудочной железы</h1> 
                <div class="v-modul-subtitle">
                   <h2>В нашей клинике осуществляется диагностика болезней печени у кошек и собак. У нас можно сделать биохимический анализ крови, Вашему животному помогут у нас в клинике, если у него гепатит, жировая дистрофия печени, цирроз печени. У нас применяются современные эффективные препараты для лечения заболеваний печени. Необходимую диагностику и помощь Ваше животное получит у нас при заболевании поджелудочной железы (панкреатит, сахарный диабет). У нас большой опыт лечения такой болезни, как сахарный диабет у кошек. Ваше животное может прожить с таким  заболеванием еще долго, если вы вовремя обратитесь к нам.</h2>
                   <picture>
                        <source srcset="../../../../assets/images/therapy/pancreas.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/therapy/pancreas.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/therapy/pancreas.jpg" class="card-img-top r-img" alt="sob">
                    </picture>
                    <h1>Возможные причины панкреатита:</h1>
                    <ul>
                        <li>Кормление, высокое содержание жира в рационе</li>
                        <li>Гиперлипопротеинемия</li>
                        <li>Токсины: инсектициды, ингибирующие холинэстеразу</li>
                        <li>Обструкция желчных протоков</li>
                        <li>Рефлюкс из двенадцатиперстной кишки</li>
                        <li>Гиперкальцемия</li>
                        <li>Травма</li>
                        <li>Ишемия/реперфузия</li>
                        <li>Инфекции</li>
                    </ul>
                    <h1>Сахарный диабет</h1>
                    <h2>К сокращению выработки инсулина приводят разные причины, но самая распространенная из них – это травмы поджелудочной железы. Одной из её основных функций является синтез оптимального количества инсулина, необходимого для регулирования уровня сахара, содержащегося в крови животного. Если поджелудочная железа повреждена, то её функции нарушаются, и это зачастую приводит к дефициту выработки инсулина, а значит – к развитию сахарного диабета.</h2>
                    <h1>Возможные причины сахарного диабета:</h1>
                    <ul>
                        <li>Неправильный рацион. От того, насколько правильно питается животное, зависит обмен веществ в его организме. Если рацион неправильный, то пищеварительные проблемы неизбежны. При этом возрастает нагрузка на поджелудочную железу, что в итоге может привести к развитию диабета.</li>
                        <li>Заболевания поджелудочной железы. К самым распространенным среди относятся разные формы панкреатита. Они представляют собой воспаление поджелудочной железы, при котором вырабатываемые ферменты постепенно разрушают её саму.</li>
                        <li>Болезни пищеварительной системы. Гастрит, язва желудка, колит, энтерит – это только часть заболеваний желудочно-кишечного тракта, которые могут привести к развитию сахарного диабета.</li>
                        <li>Тяжелые хронические заболевания. Спровоцировать возникновение сахарного диабета у животных могут хронические заболевания печени (например, гепатит) либо желчного пузыря (например, холецистит).</li>
                        <li>Частые стрессы. Психоэмоциональная нестабильность часто приводит к нарушениям в работе желез внутренней секреции и в синтезе гормонов. В итоге возникают проблемы с пищеварительной системой и поджелудочной железой.</li>
                        <li>Гормональная терапия. Выработка инсулина часто снижается на фоне длительного приема животными гормональных медикаментов. Обычно они применяются для регулирования полового поведения.</li>
                    </ul>
                    <picture>
                        <source srcset="../../../../assets/images/therapy/diabet.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../../assets/images/therapy/diabet.jpeg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../../assets/images/therapy/diabet.jpeg" class="card-img-top r-img" alt="sob">
                    </picture>
                    <h2>К факторам, повышающим риск заболевания, относится наследственная предрасположенность к сахарному диабету, ожирение (избыточная масса тела животного) и возраст</h2>
                    <h2>Первое, что указывает на развитие сахарного диабета – это повышенное мочеиспускание и сильная жажда. Эти состояния возникают на фоне очень высокого уровня глюкозы в крови и моче. Именно на эти отклонения многие хозяева обращают внимание в первую очередь.</h2>
                    <h2>Признаки сахарного диабета у животных:</h2>
                    <ul>
                        <li>обильное и частое мочеотделение, возможно недержание мочи.</li>
                        <li>сильная жажда</li>
                        <li>постоянный голод</li>
                        <li>быстрая потеря веса</li>
                        <li>усталость, апатия, недостаток энергии</li>
                        <li>вялость, отказ от игр</li>
                        <li>диспепсические расстройства (приступы рвоты, понос)</li>
                        <li>шаткая, неуверенная походка</li>
                        <li>возможен специфический запах ацетона</li>
                        <li>тусклая шерсть</li>
                    </ul>
                    <h2>Если вы заметили несколько признаков сахарного диабета у своего питомца, то не откладывайте визит к ветеринару – сразу запишитесь на прием!</h2>
                </div>                                        
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>            
        </div> 
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vPancreas',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}

</script>
<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>