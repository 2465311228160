<template>
    <div class="v-drip">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Как поставить капельницу</h1> 
                <div class="v-modul-subtitle">Внутривенная инфузия - это введение в организм растворов.Применяется для животных в тяжёлом состоянии, при быстром и резком обезвоживании, остром течении заболеваний и в других случаях.
                    <picture>
                        <source srcset="../../../assets/images/recommendations/drip2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/recommendations/drip2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/recommendations/drip2.jpg" class="card-img-top r-img" alt="Капельница">
                    </picture>
                    <h2>Подготовка к постановке:</h2>
                   <ul>
                        <li>подготовьте к квартире удобное место для постановки капельницы</li>
                        <li>животное удобнее капать на столе, на стол можно положить одеяло, одноразовую пеленку.</li>
                        <li>подготовьте флакон (пакет) для капельной инфузии с необходимым (отмеренным) количеством раствора для инфузии. </li>
                        <li>наберите препараты для струйного введения в шприцы, в нужных дозировках. Если необходимо, разведите их.</li>
                        <li>шприцы, с набранными препаратами, подпишите маркером (название препарата).</li>
                   </ul>
                   <h2>Заправьте капельную систему следующим образом:</h2>
                   <ul>
                        <li>вскройте упаковку с капельной системой</li>
                        <li>закройте роликовый зажим, для этого переведите его вниз</li>
                        <li>снимите защитный колпачок с иглы для флакона и вставьте иглу полностью во флакон с инфузионным раствором, проткнув резиновую пробку на флаконе</li>
                        <li>флакон с раствором для капельницы подвесьте над столом на гвоздик, ручку шкафа или на другой подходящий предмет на уровне значительно выше стола, иначе капельница капать не будет</li>
                        <li>далее двумя пальцами несколько раз сожмите и отпустите подигольную область (фильтр), чтобы она заполнилась раствором наполовину</li>
                        <li>для выпуска воздуха из системы откройте роликовый зажим до появления раствора из системы.</li>
                        <li>убедитесь, что в системе нет воздуха (воздушных пузырьков). Если они есть, то снова откройте зажим и дождитесь пока воздушный пузырек выйдет из системы.</li>
                   </ul>
                   <h5>Постановка капельницы</h5>
                   <picture>
                        <source srcset="../../../assets/images/recommendations/drip3.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/recommendations/drip3.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/recommendations/drip3.jpg" class="card-img-top r-img" alt="Капельница">
                    </picture>
                   <h2>Постановка капельницы:</h2>
                   <ul>
                        <li>положите животное на подготовленный стол набок</li>
                        <li>разбинтуйте катетер</li>
                        <li>промойте катетер. Раствор для промывания катетера готовится следующим образом: берете шприц на 5 мл, набираете в шприц 0,9 % NaCl (физраствор) до 5 мл. Для промывания катетера откройте верхний клапан катетера (2), подсоедините к нему шприц без иглы с раствором для промывания и введите 2-3 мл, закройте клапан. Если верхнего клапана в катетере нет, то промывание катетера осуществляется через канюлю для соединения с капельной системой (1)</li>
                        <li>открутите колпачок катетера (сбоку) и подсоедините канюлю капельной системы (без иглы) (1)</li>
                        <li>открыв зажим, включите капельницу (3). Отрегулируйте скорость введения раствора. Средняя скорость введения раствора для кошки – 20 капель в минуту, т.е введение 100 мл раствора должно занять в среднем 2 часа.</li>
                        <li>во время капельницы вводите дополнительные препараты для струйного введения в резинку (4) (шприц с иглой).</li>
                        <h2>Все препараты вводятся медленно!</h2>
                        <li>когда весь раствор прокапает, выключите капельницу, закрыв зажим (5)</li>
                        <li>отсоедините канюлю капельной системы от катетера (1), заверните колпачок катетера.</li>
                        <li>откройте верхний клапан катетера (2), промойте катетер (см. пункт 3). Закройте клапан.</li>
                        <li>забинтуйте катетер.</li>
                   </ul>
                   <h2>Внимание!</h2>
                   <h2>При появлении отека лапы, покраснения, местного повышения температуры, непроходимости катетера, подтекании, а также при болезненных ощущениях животного необходимо срочно обратиться в клинику.</h2>
                </div>                                                      
                </div>   
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>            
        </div> 
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vDrip',
    components: {
        vSide,
        vContacts,
    }, 
    mixins: [resize]
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/modul.scss';
</style>