<template>
    <div class="v-pets">
        <div class="container">
            <div class="v-modul-block">
                <div class="v-modul-block-left">
                <h1 class="v-modul_title">Готовимся к появлению домашнего питомца</h1>                
                <div class="v-modul-subtitle">Маленькие четвероногие друзья — полноправные члены нашей семьи. Если семейный совет одобрил нового жителя, на примете есть хороший ветеринар, самое время заняться подготовкой дома к приходу котенка или щенка.                  
                <picture>
                    <source srcset="@/assets/images/vetarticles/pets.webp" class="card-img-top r-img" type="image/webp">
                    <source srcset="@/assets/images/vetarticles/pets.jpg" class="card-img-top r-img" type="image/jpeg"> 
                    <img src="@/assets/images/vetarticles/pets.jpg" class="card-img-top r-img" alt="Домашний питомец">
                </picture>
                <!-- <div id="yandex_rtb_R-A-11783043-1"></div> -->
                <h1>Что понадобится</h1>
                <h2>Животное должно прийти в дом, где уже все есть и подготовлено для его появления. Поэтому заранее приобретите все необходимые аксессуары. А именно:</h2>
                    <ul>
                        <li>Место для еды. Мисочки для кормежки лучше размещать на кухне. Следует приобрести несколько мисок, одна из которых отводится под воду. Для обеспечения чистоты под миски подложите прорезиненный нескользящий коврик. Для кошек мисочку с водой лучше размещать поодаль от емкости для основной еды.</li>
                        <li>Туалет. Сам лоток и наполнитель к нему. Кстати, лоточек потребуется не только для котенка, им активно будут пользоваться и породы декоративных собак, а также маленькие щенята.</li>
                        <li>Лежанка. Лежаночку лучше расположить в защищенном от сквозняков месте, где тихо, спокойно и уютно. Для будущих защитников и сторожей дома размещайте лежанку в коридоре. А вот любопытные и флегматичные с возрастом кошки будут с удовольствием наблюдать за происходящем за окном. Для них оптимальный вариант — лежанка на подоконнике.</li>
                        <li>Аксессуары для выгула. Намордники, ошейники, шлейки, поводки для собак с учетом размера животного. Причем поводки могут пригодиться и для выгула кошек, если не предусмотрено, что они будут чисто домашними.</li>
                        <li>Игровой угол. Когтеточка, многочисленные «погрызунчики», забавные игрушки, которые можно приобрести в любом зоомагазине. Для активных кошек, обожающих куда-нибудь взбираться, можно разнообразить интерьер ступеньками, полочками с удобными переходами.</li>
                    </ul> 
                    <h2>Это лишь предварительный список. Многие позиции могут корректироваться и дополняться. Но перечень необходимых вещей необходимо составлять и закупать все нужное до появления питомца. Это сэкономит вам силы и время и позволит окружить малыша необходимым комфортом с первых дней, помогая ему быстрее акклиматизироваться в новом доме.</h2>                                 
                <h1>Не только безопасность питомца, но и комфорт хозяина</h1>
                <h2>Обзаведясь шаловливым несмышленышем, обеспечьте его полную безопасность. Например, растения. Животные не станут обгрызать все цветки подряд — им не позволит инстинкт самосохранения. А вот ворошить и раскидывать грунт станут обязательно. Поэтому уберите цветы повыше, чтобы не переживать за их сохранность.</h2>
                <h2>То же касается и мелких предметов, проводов — в этом плане самосохранение животных не работает. Как и маленькие дети, котята и щенята обязательно попробуют на вкус кабели и мелкие детали. Электрические шнуры можно спрятать под плинтусы или в специализированные короба. Из зоны видимости следует убрать и хрупкие предметы, от которых могут откалываться частички</h2>                               
                <h1>Ковер или плинтус?</h1>
                <h2>Естественная среда обитания животных — свободная уличная территория. Конечно, есть выведенные декоративные породы, которые не приспособлены для улицы, но даже у них еще остался природный инстинкт копания земли. Поэтому (и для собственного удобства тоже) пока питомец маленький, снимите ковры, чтобы не заниматься ежедневной уборкой. Это особенно актуально для малышей, которые еще не приучены к лотку и могут оставлять сюрпризы в виде луж.</h2>
                <h2>Но помните, что маленькие щенки и котята любят играться с различными шуршащими предметами. Их роль обычно выполняют крепление и заглушки плинтусов. Поэтому обязательно укрепите все дополнительные элементы заранее, до появления маленького друга.</h2>                               
                <h1>Важные рекомендации</h1>
                <h2>Для щенка и котенка новый дом представляется большим, очень интересным пространством, которое необходимо досконально исследовать. А такое любопытство из-за легкомыслия хозяева может дорого обойтись малышу.Поэтому:</h2>  
                    <ul>
                        <li>спрячьте мусорное ведро, чтобы животное не отравилось объедками и не разбросало их по квартире;</li>
                        <li>уберите всю бытовую химию и лекарства в недоступные места;</li>
                        <li>выключайте вентиляторы и не оставляйте маленьких питомцев вблизи работающей техники без присмотра;</li>
                        <li>закройте окна или обеспечьте их надежными москитными сетками (можно оснастить проемы специальными приспособлениями «антикошка»).</li>
                    </ul>
                <h2>И будьте готовы к маленьким, а иногда и к масштабным погромам. Ну а чтобы не расстраиваться, спрячьте все дорогие сердцу сувениры, дизайнерские аксессуары подальше. Помните о природном любопытстве малышей. К общей подготовке домашнего пространства подходите индивидуально. Обдумайте, с какими опасностями может столкнуться новый житель, и сделайте все для недопущения неприятной ситуации.</h2>
                <h2>Таких правил вполне достаточно, чтобы щенок или котенок, придя в дом, быстро акклиматизировались и привыкли к владельцам. Но если животное показывает проблемы в социализации, стоит обратиться за помощью к зоопсихологам. Профессионалы помогут откорректировать нюансы поведения и посодействуют в воспитании.</h2>                  
                </div>
                </div>
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>
        </div>
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
// import { onMounted } from 'vue'

export default {
    name: 'v-pets',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize],
//     setup(){
//         onMounted(()=>{
//             window.yaContextCb.push(() => {
//             Ya.Context.AdvManager.render({
//             "blockId": "R-A-11783043-1",
//             "renderTo": "yandex_rtb_R-A-11783043-1"
//     })
// })
//         })
//     }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/modul.scss';
</style>