import { render, staticRenderFns } from "./v-modal.vue?vue&type=template&id=275e684b&scoped=true"
import script from "./v-modal.vue?vue&type=script&lang=js"
export * from "./v-modal.vue?vue&type=script&lang=js"
import style0 from "./v-modal.vue?vue&type=style&index=0&id=275e684b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275e684b",
  null
  
)

export default component.exports