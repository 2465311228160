<template>
    <div class="v-uzi">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">УЗИ-диагностика</h1> 
                <div class="v-modul-subtitle"> Ультразвуковое исследование - один из самых распространенных и безболезненных методов визуализации внутренних органов в современной ветеринарии.
                    <h2>Ультразвуковое исследование для животных – это современный, эффективный и информативный вид диагностики различных органов. Данные ультразвукового исследования позволяют более точно поставить диагноз. При этом УЗИ не представляет опасности для здоровья питомца.</h2>
                    <h2>С помощью УЗИ можно исследовать различные органы грудной и брюшной полости. Оценить их состояние и структуру, что не обходимо для дальнейшей тактики лечения. А так же можно определить наличие новообразования или инородного тела.</h2>
                    <picture>
                        <source srcset="../../../assets/images/uzi/uzi1.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/uzi/uzi1.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/uzi/uzi1.jpg" class="card-img-top r-img" alt="УЗИ-диагностика">
                    </picture>
                    <h2>Наиболее часто данное исследование назначают в следующих случаях:</h2>
                    <ul>
                        <li>для постановки диагноза</li>
                        <li>травмы брюшной/грудной полости</li>
                        <li>потеря аппетита и веса</li>
                        <li>затрудненное мочеиспускание</li>
                        <li>болезненность в области живота</li>
                        <li>диарея</li>
                        <li>рвота</li>
                        <li>беременность</li>
                        <li>возраст питомца старше 7 лет (профилактика заболеваний)</li>
                        <li>контрольное УЗИ в процессе лечения</li>
                        <li>отслеживание новообразований</li>
                    </ul>
                    <h2>В чем же преимущества использования данного метода диагностики в целом и в частности для животных (кошек и собак)? В первую очередь это высокая информативность. Данный метод пригоден для исследования большинства органов живого организма, таких как печень, желчный пузырь, поджелудочная железа, селезенка, почки, мочевой пузырь, предстательная железа. УЗИ применяют в кардиологии (эхокардиография), при исследовании щитовидной железы, в офтальмологии, в акушерстве и гинекологии (определение срока беременности, оценка развития и жизнеспособности плодов) и др.</h2>
                    <h2>При исследовании вышеперечисленных органов с помощью ультразвуковых волн можно диагностировать достаточно большое количество заболеваний как у собаки, так и у кошки: онкологические заболевания, диффузные изменения в печени и поджелудочной железе, почках и паренхиме почек, предстательной железе, наличие конкрементов в желчном и мочевом пузырях, почках, наличие аномалий внутренних органов, жидкостных образований в органах и т. д.</h2>
                    <picture>
                        <source srcset="../../../assets/images/uzi/uzi2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/uzi/uzi2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/uzi/uzi2.jpg" class="card-img-top r-img" alt="Узи у животных">
                    </picture>
                    <h2>К следующим преимуществам метода можно отнести безопасность УЗИ, его простоту и безболезненность. Для исследования не нужно обездвиживать животное медикаментозно, нет необходимости предварительно осуществлять какие-либо хирургические вмешательства. УЗИ — аппарат не издает шума, который мог бы напугать животное. А ведь известно, что кошки и собаки очень боязненно относятся к шуму.</h2>
                    <h2>Ультразвуковые волны не оказывают негативное воздействие как на исследуемое животное, так и на самого специалиста. К тому же использование специальных контактных жидкостей избавило от необходимости выбривать шерсть у животного. Еще одним достоинством ультразвукового исследования является его неинвазивность, то есть для получения информации не нужно проникать в закрытые полости организма. Также следует отметить возможность многократного применения метода без вреда для здоровья.</h2>
                    <h2>Как подготовить к УЗИ животное</h2>
                    <ul>
                        <li>не кормить животное в течение 12-и часов до исследования</li>
                        <li>не водить в туалет, исследование проводится на полный мочевой пузырь</li>
                        <li>при соблюдении этих условий качество УЗИ значительно повышается</li>
                    </ul>
                </div>                                       
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>             
        </div>       
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vUzi',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}
</script>
<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>