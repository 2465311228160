<template>
    <div class="v-lab">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Лабораторная диагностика</h1> 
                <div class="v-modul-subtitle"> Диагностическое значение анализов в ветеринарной медицине очень велико. Животное не может сказать, что именно у него болит, поэтому анализы важны для формирования доктором полной картины заболевания. В первую очередь нужно сдать общий клинический и биохимический анализы крови,а так же анализ мочи и кала, Эти исследования покажут общее состояние организма и органов животного.Организм всегда подвергается воздействию различных факторов окружающей среды и дает специфическую ответную реакцию на раздражители. Каждая клеточка крови выполняет свои функции по защите организма. При повышении или понижении количества определенных клеток можно говорить о возможной причине заболевания. 
                    <picture>
                        <source srcset="../../../assets/images/lab/lab1.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/lab/lab1.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/lab/lab1.jpg" class="card-img-top r-img" alt="Лабораторная диагностика">
                    </picture>
                    <h1>Общий клинический анализ крови</h1>
                    <h2>Общий клинический анализ крови может показать степень развития воспалительного процесса, имеется ли анемия, обезвоживание, а также есть ли новообразования в системе крови, или нет. Также не нужно забывать о скрытых (хронических) инфекционных, инвазионных или каких-либо других процессах неинфекционного характера в организме, которые также можно обнаружить при исследовании крови как одного из методов диагностики. </h2>
                    <h1>Биохимический анализ крови</h1>
                    <h2>Биохимический анализ крови – это метод лабораторной диагностики, позволяющий на основании измерения определенных параметров, получить представление о состоянии обмена веществ (белков, углеводов, жиров), а также о работе различных внутренних органов.Данный анализ отличается информативностью и достаточно высокой достоверностью</h2>
                    <h2>При помощи результатов биохимического анализа крови собак и кошек можно судить о функционировании почек, печени, желчного пузыря, поджелудочной железы и других органов, а также выявить недостаток микроэлементов и витаминов. Биохимический анализ крови используется в гастроэнтерологии, терапии, хирургии, кардиологии и других направлениях ветеринарной медицины.</h2>
                    <h2>Проведение данного исследования во время лечения даёт возможность оценивать динамику заболевания. Профилактическое взятие анализа может помочь выявить заболевание, которое клинически ещё никак не проявилось.</h2>
                    <h1>Анализ мочи</h1>
                    <h2>Анализ мочи у животных.Позволяет непосредственно оценить функцию почек и выявить воспалительный процесс в мочевых путях.</h2>
                    <h2>Его проводят при таких патологиях как:</h2>
                    <ul>
                        <li>заболевания и воспалительные процессы мочевыводящих путей (исследование на слабокислую, нейтральную или щелочную реакцию)</li>
                        <li>мочеполовой системы (повышенный уровень лейкоцитов)</li>
                        <li>мочекаменной болезни (появление эритроцитов и кристаллов моче)</li>
                        <li>сахарный диабет (наличие в моче глюкозы)</li>
                        <li>застойные процессы (наличие слизи) и многое другое</li>
                    </ul>
                    <h1>Анализ кала</h1>
                    <h2>Анализ кала у животных. Данный вид исследования проводится с целью исключить гельминтозные и протозойные болезни, а также при хронических патологиях желудочно-кишечного тракта.</h2>
                    <h2>С его помощью обнаруживаются заболевания воспалительного генеза, нарушения всасывания в кишечнике, нарушение работы поджелудочной железы (по переваримости питательных веществ), а также оценивают кишечную микрофлору.</h2>
                    <h2>Анализ кала у собак и кошек проводится и назначается лечащим врачом при наличии видимой крови и обильной слизи в фекалиях, поносе, рвоте. Врачи ветеринарной клиники “ЛИС” рекомендуют проводить анализ кала у собак и кошек профилактически, поскольку гельминты и простейшие могут присутствовать в кишечнике у животных с нормальным стулом.</h2>
                    <h2>Анализ включает в себя методы исследования:</h2>
                    <ul>
                        <li>макроскопический</li>
                        <li>биохимический</li>
                        <li>биохимический</li>
                    </ul>
                    <h2>Простейшие и яйца глист исследуется флотационным методом с гипертоническим раствором натрия хлорида и методом нативного мазка</h2>
                    <h2>Анализ кала недорогой, доступный и быстрый метод, позволяющий идентифицировать яйца глист и ооцисты простейших</h2>
                    <h1>Виды анализов крови</h1>
                    <h2>Виды анализов крови, исследуемый материал.</h2>
                    <h2>Существует два основных лабораторных анализа крови:</h2>
                    <ul>
                        <li>общий (или клинический);</li>
                        <li>биохимический</li>
                    </ul>
                    <h1>Общий (клинический) анализ крови </h1>
                    <h2>Общий (клинический) анализ крови у кошки.Показывает состояние здоровья организма в целом по количеству и состоянию форменный кровяных элементов. Также по данному анализу можно определить присутствие в крови особых паразитов – гемобартенелл и дирофилярий.</h2>
                    <h2>Материал для анализа:</h2>
                    <h2>Венозная кровь не менее 2 мл, помещенная в пробирку со специальной антикоагулянтной средой (гепарин или цитрат натрия), предупреждающей ее свертывание и разрушение кровяных форменных элементов (клеток крови).</h2>
                    <h1>Биохимический анализ крови</h1>
                    <h2>Выявляются скрытые патологии в организме кошки. Исследование дает информацию о поражении какого-то отдельного органа или конкретной системы органов, а также объективную оценку степени данного поражения. Результат определяется по работе ферментативной системы, отраженной на состоянии крови. Биохимический анализ крови у кошки включает себя ферментные, электролитные, жировые и субстратные показатели.</h2>
                    <h2>Материал для анализа:</h2>
                    <h2>Сыворотка крови объемом около 1 мл (венозная кровь, взятая натощак и помещенная в специальную пробирку, которая позволяет отделить сыворотку крови от ее форменных элементов)</h2>
                    <h2>Основные показатели анализов крови и их характеристики</h2>
                    <h2>Каждый показатель отвечает за ту или иную степень здоровья/нездоровья в организме кошки, а также показывает работу отдельных органов или целых систем. Значение имеет не только каждые данные в отдельности, но и по соотношению друг к другу.</h2>
                    <h1>Общий (клинический) анализ крови</h1>
                    <h2>Гематокрит – условный показатель, показывающий соотношение всех форменных элементов крови к ее общему объему. Другое название – гематокритное число и часто определяется соотношение не всех клеток крови, а только эритроцитов. Другими словами – это густота крови. Показывает, на сколько кровь способна переносить кислород.</h2>
                    <h2>Гемоглобин – содержимое эритроцитов, отвечающих за транспортировку кислорода по организму и выведение отработанного углекислого газа. Отклонение от нормы всегда является признаком той или иной патологии в системе кровообращения.</h2>
                    <h2>Средняя концентрация гемоглобина в эритроците показывает в процентном соотношении, на сколько эритроциты насыщены гемоглобином.</h2>
                    <h2>Среднее содержание гемоглобина в эритроците имеет примерно аналогичное значение с предыдущим показателем, только результат отмечается конкретным количеством его в каждом эритроците, а не общим процентным соотношением.</h2>
                    <h2>Цветной (цветовой) показатель крови показывает, сколько в эритроцитах содержится гемоглобина, по отношению к нормальному значению.</h2>
                    <h2>СОЭ – показатель, по которому определяются следы воспалительного процесса. Скорость оседания эритроцитов не указывает на конкретную болезнь, а говорит о наличии нарушений. В каком конкретном органе или системе можно определить в совокупности с другими показателями.</h2>
                    <h2>Эритроциты – красные кровяные клетки крови, принимающие участие в тканевом газообмене, удерживании кислотно-щелочного баланса. Плохо, когда результаты анализов выходят за рамки нормы не только в сторону снижения, но и роста</h2>
                    <h2>Лейкоциты – или белые клетки крови, которые показывают состояние иммунной системы животного. Включают в себя лимфоциты, нейтрофилы, моноциты, базофилы, базофилы и эозинофилы.</h2>
                    <h2>Диагностическое значение имеет соотношение всех этих клеток между собой:</h2>
                    <ul>
                        <li>нейтрофилы – отвечают за уничтожение бактериальной инфекции в крови;</li>
                        <li>лимфоциты – общий показатель иммунитета;</li>
                        <li>моноциты – занимаются уничтожением чужеродных веществ, попавших в кровь и угрожающих здоровью;</li>
                        <li>эозинофилы – стоят на страже в борьбе с аллергенами;</li>
                        <li>базофилы – «работают» в тандеме с другими лейкоцитами, помогая распознавать и выявлять чужеродные частицы в крови.</li>
                        <li>Тромбоциты – клетки крови, отвечающие за ее свертываемость. Они же отвечают за целостность сосудов. Значение имеет, как рост этого показателя, так и его снижение.</li>
                        <li>Миелоциты считаются разновидностью лейкоцитов, однако идут несколько обособленным показателем, т.к. находятся в костном мозге и в норме в крови выявляться не должны.</li>
                    </ul>
                    <picture>
                        <source srcset="../../../assets/images/lab/lab2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/lab/lab2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/lab/lab2.jpg" class="card-img-top r-img" alt="Анализы">
                    </picture>
                    <h1>Биохимический анализ крови</h1>
                    <h2>Глюкоза считается очень информативным показателем, т.к. указывает на работу сложной ферментативной системы в организме, включая отдельные органы. В круговороте глюкозы задействовано 8 различных гормонов и 4 сложных ферментативных процесса. Патологией считается, как рост уровня сахара в крови у кошки, так и его падение</h2>
                    <h2>Общий белок в крови отражает правильность аминокислотного (белкового) обмена в организме. Показывает суммарное количество всех белковых составляющих – глобулинов и альбуминов. Все белки принимают участие практически во всех жизнедеятельных процессах организма, поэтому важны как их количественный рост, так и снижение</h2>
                    <h2>Альбумин – самый главный кровяной белок, вырабатываемый печенью. Выполняет массу жизненно важных функций в организме кошки, поэтому всегда определяется отдельным от общего белка показателем (перенос полезных веществ, сохранение резервных запасов аминокислот для организма, сохранение осмотического давления крови и др.)</h2>
                    <h2>Холестерин является одной из структурных клеточных компонентов, обеспечивая их прочность, а также участвует в синтезе многих жизненно важных гормонов. По нему также можно судить о характере липидного обмена в организме кошке.</h2>
                    <h2>Билирубин – желчный компонент, состоящий из двух форм – непрямой и прямой. Непрямой образуется из эритроцитного распада, а связанный (прямой) преобразуется в печени из непрямого. Напрямую показывает работу гепабилиарной системы (желчевыделительной и печеночной). Относится к «цветным» показателям, т.к. при его превышении в организме ткани окрашиваются в желтый цвет (признак желтухи).</h2>
                    <h2>Аланинаминотрансфераза (АЛТ, АЛаТ) и аспартатаминотрансфераза (АСТ, АСаТ) – ферменты, вырабатываемые клетками печени, скелетной мускулатурой, клетками сердца и эритроцитами. Является прямым показателем функций этих органов или отделов.</h2>
                    <h2>Лактатдегидрогеназа (ЛДГ) – фермент, который участвует в конечном этапе расщепления глюкозы. Определяют для контроля работы печеночной и сердечной системы, а также при рисках образования опухолей.</h2>
                    <h2>ɤ-глутамилтрансфераза (Гамма-ГТ) – в комплексе с другими печеночными ферментами дает представление о работе гепабилиарной системы, поджелудочной и щитовидной желез.</h2>
                    <h2>Щелочная фосфотаза определяется для контроля работы печени.</h2>
                    <h2>ɑ-Амилаза – вырабатывается поджелудочной и околоушной слюнной железой. По ее уровню судят об их работе, но обязательно в совокупности с другими показателями.</h2>
                    <h2>Мочевина – итог переработки белка, который выводится почками. Часть остается циркулировать в крови. По данному показателю можно проверить работу почек.</h2>
                    <h2>Креатинин – побочный мышечный продукт, выводимый из организма почечной системой. Уровень колеблется в зависимости от состояния выделительной мочевой системы.</h2>
                    <h2>Калий, кальций, фосфор и магний оцениваются всегда в комплексе и соотношениях между собой.</h2>
                    <h2>Кальций является участником проведения нервных импульсов, особенно через сердечную мышцу. По его уровню можно определить проблемы в работе сердца, сократительных свойств мышц и свертываемости крови</h2>
                    <h2>Креатинфосфокиназа – фермент, который в огромном количестве содержится в скелетной группе мышц. По его наличию в крови можно судить о работе сердечной мышцы, а также внутренних мышечных травм.</h2>
                    <h2>Триглицериды в крови характеризуют работу сердечно-сосудистой системы, а также энергетический обмен. Обычно анализируется в комплексе с уровнем холестерина.</h2>
                    <h2>Электролиты отвечают за мембранные электрические свойства. Благодаря электрической разнице потенциалов клетки улавливают и исполняют команды мозга. При патологиях клетки в буквальном смысле «выбрасываются» из системы проводимости нервных импульсов.</h2>
                </div>                                       
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>             
        </div>
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vLab',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}
</script>

<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>