<template>
<!-- Yandex.RTB R-A-11783043-1 -->
<div id="yandex_rtb_R-A-11783043-1"></div>
</template>

<script>
export default {
    name:'v-Yan'
}
</script>

<script>
window.yaContextCb.push(() => {
    Ya.Context.AdvManager.render({
        "blockId": "R-A-11783043-1",
        "renderTo": "yandex_rtb_R-A-11783043-1"
    })
})
</script>