<template>
    <div class="v-eye">
        <div class="container">
            <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Офтальмология</h1> 
                <div class="v-modul-subtitle">Глаз — очень тонкая и сложноорганизованная структура
                    <h2>Болезни органов зрения очень актуальны у животных, они имеют определенную специфику.</h2> 
                    <h2>В связи с большим разнообразием пород собак и кошек, существуют видовые и породные особенности строения и функции органа зрения и его вспомогательного аппарата, а отсюда соответственно и предрасположенность к тем или иным офтальмологическим патологиям у отдельных представителей.</h2>
                    <picture>
                        <source srcset="../../../assets/images/eye/oko2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/eye/oko2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/eye/oko2.jpg" class="card-img-top r-img" alt="Лечение глаза">
                    </picture>
                    <h2>Основными клиническими проявлениями заболевания глаз вашего питомца являются:</h2>
                    <ul>
                        <li>слезотечение и видимые гнойные выделения из глаза или в конъюнктивальном мешке;</li>
                        <li>увеличение глазного яблока в размере;</li>
                        <li>прищуривание</li>
                        <li>светобоязнь;</li>
                        <li>любые травматические повреждения глаза</li>
                    </ul>
                    <h2>Число нозологических форм глазной патологии у домашних животных велико, заболевания могут быть как первичные, т. е. заболевания собственно органа зрения, так и вторичные, т.е. когда патологические изменения структур и тканей глазного яблока происходят вследствие системных болезней организма (сахарный диабет, почечная недостаточность, артериальная гипертензия, гормональные нарушения и др.) или при воздействии на него химических (отравления, медикаментозные воздействия), или физических (травмы, ожоги) факторов.</h2>  
                    <h2>Широко распространены заболевания вирусной этиологии: вирусные агенты которых обладают тропностью к тканям конъюнктивы и роговицы, и вызывают в них острые воспалительные процессы, что приводит к серьезному поражению структур глазного яблока с тяжелыми последствиями в виде снижения остроты зрения а в некоторых случаях и его потерей.</h2>
                    <picture>
                        <source srcset="../../../assets/images/eye/oko1.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/eye/oko1.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/eye/oko1.jpg" class="card-img-top r-img" alt="Лечение глаза">
                    </picture>
                    <h2>Инфекционные заболевания, при которых поражаются органы зрения:</h2>
                    <ul>
                        <li>Чума плотоядных — тяжелое вирусное заболевание. При чуме у собак наблюдаются поражения глаз: острый и хронический конъюнктивит, сухой кератоконъюнктивит, передний увеит, ретинохориоидит (хориоретинит), воспаление зрительного нерва.</li>
                        <li>Аденовирусная инфекция собак так же может иметь офтальмологические проявления и сопровождаться конъюнктивитом и передним увеитом. Аденовирус собак I типа (CAV-1) вызывает острый передний увеит с отеком роговицы</li>
                        <li>Кошачий герпесвирус (FHV), калицивирус, Chlamydophila felis и Mycoplasma spp. являются наиболее распространенными причинами инфекционного конъюнктивита у кошек. Из них кошачий герпесвирус (FHV) и Сh. Felis являются на сегодняшний день самыми актуальными. Особенно распространены эти инфекции в условиях питомников, где они представляют поистине глобальную проблему.</li>
                        <li>FIP (инфекционный перитонит кошек) и FIV- (вирусный имунодеффицит кошек)- злокачественные вирусные инфекции, наиболее часто являются причиной увеита и хориоретинита.</li>
                    </ul>
                    <h2>Микробные, грибковые, протозойные возбудители играют так же не последнюю роль в развитии глазных проблем воспалительного характера.</h2>
                    <h2>Офтальмологическое лечение больных животных проводится совместно со специфическим системным лечением, т.е. комплексно.</h2>
                    <h2>Для профилактики рекомендуется ежегодная вакцинация животных.</h2>
                    <h2>Особую группу заболеваний составляют наследственно обусловленные (генетические) и врожденные болезни глаз у собак.</h2>
                    <h2>Нужно сказать, что врожденные заболевания не всегда являются наследственными и могут возникнуть в результате патологии развития плода, при воздействии, например, каких- либо тератогенных факторов</h2>
                    <h2>Эти заболевания регистрируются у разных пород собак и затрагивают все структуры глазного яблока. Наиболее известные, и, к сожалению, все чаще встречающиеся из них, это:</h2>
                    <ul>
                        <h2>ПРОГРЕССИРУЮЩАЯ АТРОФИЯ СЕТЧАТКИ</h2>
                        <li>Прогрессирующая атрофия сетчатки — наследственное заболевание аутосомно-рецессивного характера вызывающее поражение фоторецепторных клеток сетчатки, с ее последующей атрофией, что в свою очередь приводит к полной потере зрения животным. Среди предрасположенных пород: лабрадор- ретривер, карликовый пудель, миниатюрная и кроличья такса, цверг- пинчер, и многие другие. Помеси этих пород и беспородные особи не составляют исключения для этого заболевания.</li>
                        <h2>ДИСПЛАЗИЯ СЕТЧАТКИ</h2>
                        <li>Первичная ретинальная дисплазия (Retinal Displasia) это врожденное, связанное с развитием заболевание сетчатки. Оно встречается у всех видов животных, в частности и у собак. Ретинальная дисплазия проявляется как образование аномальных складок и розеток в чувствительной (зрительной) зоне сетчатки. Ретинальная дисплазия может также обнаруживаться при инфекционных заболеваниях, отравлениях и множестве глазных патологий. Поэтому RD является одновременно и врожденным (приобретенным), и наследственным заболеванием.
                        <br>Ретинальная дисплазия встречается у Американских кокер спаниелей, Бедлингтон терьеров, Английских спрингер спаниелей, Лабрадор -ретриверов, Ротвейлеров, Самоедов, Селихэм терьеров и Йоркшир-терьеров; встречается вместе с другими глазными патологиями у Акиты, Австралийской пастушьей собаки, Чау-чау, Доберманов, Лабрадор ретриверов и Самоедов.</li>
                        <h2>КАТАРАКТА</h2>
                        <li>Катаракта — офтальмологическое заболевание, связанное с помутнением хрусталика глаза и вызывающее различные степени расстройства зрения. Катаракта — состояние необратимое и встречается в любом возрасте. Проблема актуальная, как для собак, так и для кошек. Бывает врожденная катаракта, травматическая, осложненная, лучевая, катаракта, вызванная общими заболеваниями организма. Существуют различные причины возникновения, формы поражения и стадии этого заболевания.  Катаракта может наследоваться у одной и той же породы несколькими различными путями. Список предрасположенных пород очень широк, среди них: сибирский хаски, английский бобтейл, цверг-шнауцер, пудель, ретриверы и многие другие).</li>
                    </ul>
                    <h2>Другой распространенной патологией хрусталика является нестабильность его фиксации на связочном аппарате.</h2>
                    <h2>Среди прочих наследственных заболеваний: одна из наиболее тяжелых болезней первичная глаукома, персистирующая зрачковая мембрана (PPM), различные виды дистрофий роговицы, отдельная группа болезней глаз Колли и многие другие</h2>
                    <h1>Болезни глаз требующие хирургического лечения</h1>
                    <h2>Больше половины глазных патологий требуют оперативного лечения, наиболее часто хирургия проводится при таких состояниях:</h2>
                    <ul>
                        <li>травмы (проникающие и тупые, ожоги)</li>
                        <li>повреждения глаза и его придатков</li>
                        <li>повреждение роговицы, радужки, хрусталика, сетчатки и сосудистой оболочки глаза. Ранения век и слезных органов. Требуют неотложной помощи при разрушении глаза и ранении глазницы.</li>
                        <li>Заворот и выворот век (entropion/ectropion)— характеризуются неправильным положением век относительно глазного яблока, в результате чего нарушается трофика тканей глаза, развивается воспаление со всеми свойственными проявлениями.</li>
                    </ul>
                    <h2>Данная проблема встречается довольно часто особенно у представителей брахицефалических пород собак и кошек (английский и французский бульдоги, мопсы, пекинесы, бордосские доги, персидские и британские кошки), у собак и кошек «складчатых» пород (мастифы, шар-пеи, чау-чау, кане-корсо, донские и канадские сфинксы), а также у собак и кошек с глубоко посаженными глазами (родезийский риджбек, мейн-кун, ориентальные и сиамские кошки).</h2>
                    <h2>Наиболее надежным методом лечения данной патологии является хирургическая пластика век.</h2>
                    <picture>
                        <source srcset="../../../assets/images/eye/oko3.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/eye/oko3.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/eye/oko3.jpg" class="card-img-top r-img" alt="Слёзная железа">
                    </picture>
                    <h6>Выпадение слезной железы Гарднера</h6>
                    <h2>Для некоторых представителей перечисленных пород собак и кошек и многих других, также весьма характерны такие явления как пролапс железы третьего века (выпадение слезной железы), дистихиаз, эктопия ресниц, конъюнктивальный или корнеальный дермоид, корнеальный секвестр у кошек, аномалии развития носослезной системы. Все эти состояния требуют оперативного лечения, и чем более своевременно оно начато, тем благоприятней прогноз исхода болезни.</h2>
                </div>                                       
                </div>               
            <v-contacts v-if="small"/>             
            <v-side v-else/>
         </div>   
        </div>
    </div>
</template>
<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
    name: 'vEye',
    components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}
</script>

<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>