<template>
    <div class="v-teeth">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Ультразвуковая чистка зубов</h1> 
                <div class="v-modul-subtitle"> Ультразвуковая чистка зубов у животных — это процесс очищения зубов от твердого налета при помощи ультразвукового скалера. Зубной камень образуется практически у всех животных старше 3 лет, чаще у собак.
                    <h1>Зубной камень у собак и кошек</h1>
                    <h2>Зубной камень представляет собой твердые отложения желтого, коричневатого и даже черного цвета. В составе такого налета присутствуют соли кальция и железа, белки, частицы эпителия, бактерии, мельчайшие кусочки пищи. Он образуется в результате скопления остатков пищи и слюны животного, которые спустя некоторое время твердеют. Отложения на зубах являются прекрасной средой для размножения различных бактерий, и как следствие, в ротовой полости питомца возникают воспаления.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom1.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom1.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom1.jpg" class="card-img-top r-img" alt="Зубной камень у собак и кошек">
                    </picture> 
                    <h2> Данная проблема лечится только ультразвуковой чисткой, различные препараты не способны растворить зубной камень. Зубной камень у собак и кошек,  может негативно сказываться на общем самочувствии животного. Питомцы могут испытывать боль при пережевывании пищи и часто даже отказываться от еды, становиться беспокойным и апатичным.</h2>
                    <h2>Зубной камень в запущенной форме может привести к полной потере зубов. Поэтому мы рекомендуем посещать ветеринара-стоматолога при первых признаках заболевания зубов и десен:</h2>
                    <ul>
                        <li>десны воспалены и покраснели</li>
                        <li>питомец не дает заглянуть в себе в пасть</li>
                        <li>питомец не позволяет трогать себя за щеки и нос</li>
                        <li>неприятный запах из пасти</li>
                        <li>один или несколько зубов сильно отличаются по цвету</li>
                        <li>животное испытывает трудности с жеванием или не может есть твердую пищу</li>
                    </ul>
                    <h2>Мы проводим ультразвуковую чистку зубов кошкам и собакам под наркозом. В случаях, когда восстановить зуб уже невозможно, его придется удалять. Также удаляем невыпавшие молочные зубы.</h2>
                    <h1>Чистка зубов собакам и кошкам под наркозом</h1>
                    <h2>Без наркоза можно снять только поверхностный налет на зубах, поэтому для лучшего эффекта мы используем мягкие седативные средства. Животное спит во время процедуры, и врач может использовать направленные струи воды и лучше очистить зубы. Мы используем мягкие седативные средства, которые не оказывают сильную нагрузку на сердце.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom3.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom3.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom3.jpg" class="card-img-top r-img" alt="Ультразвуковая чистка зубов">
                    </picture> 
                    <h2> Пациентам из группы риска перед проведением плановой санации ротовой полости мы рекомендуем пройти полное обследование, чтобы свести к минимуму возможные риски.</h2>
                    <h1>Причины возникновения зубного камня</h1>
                    <h2>Среди наиболее частых причин возникновения твердого налета на зубах выделяют:</h2>
                    <ul>
                        <li>употребление в пищу мягких кормов;</li>
                        <li>наследственная предрасположенность у некоторых пород;</li>
                        <li>нарушение обменных процессов;</li>
                        <li>возраст животного;</li>
                        <li>неправильное питание, в частности переизбыток сахаросодержащих продуктов в рационе;</li>
                        <li>недостаточная гигиена зубов животного;</li>
                        <li>неправильный прикус;</li>
                        <li> задержка смены зубов.</li>
                    </ul>
                    <h1>Профилактика образования зубного камня у животных</h1>
                    <h2>Профилактикой возникновения зубного камня является ежедневная чистка зубов собаке или кошке с помощью щёток и паст, предназначенных для животных; можно также применять специальные пасты, использующиеся в ветеринарной стоматологии, которые необходимо просто наносить на зубы один раз в неделю.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom2.jpg" class="card-img-top r-img" alt="Профилактика образования зубного камня у животных">
                    </picture> 
                    <h2>На скорость появления зубного налёта и камня влияют рацион и активность питомца. В природе хищники используют зубы для захвата, удержания добычи и пережёвывания костей, жил и т.д. Поэтому, если животное получает в качестве корма вместо твёрдой пищи исключительно мягкую и измельчённую (консервы, паучи, каши, супы), зубы не очищаются как следует.</h2>
                    <h2>Важна нагрузка на все группы зубов, поэтому в рационе собак и кошек должны быть продукты, которые они могут грызть, жевать, кусать и т.д. Также, согласно исследованиям, физические нагрузки, благодаря своему влиянию на водно-солевой обмен, профилактируют образование зубного налёта и камня, поэтому выгуливать собаку необходимо чаще.</h2>
                    <h2>Важно следить за состоянием полости рта домашнего питомца и периодически осматривать его зубы на предмет наличия налёта или зубного камня и своевременно обращаться в ветеринарную клинику, где будет проведена чистка зубов ультразвуком, для того чтобы избежать их потери, сильных воспалительных процессов в ротовой полости домашнего питомца, нарушений работы желудочно- кишечного тракта.</h2>
                </div>                                                       
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>             
        </div>       
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
  name: 'vTeeth',
  components: {
        vSide,
        vContacts,
    }, 
    mixins: [resize] 
}
</script>
<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>