import { render, staticRenderFns } from "./v-about.vue?vue&type=template&id=41ce345a&scoped=true"
import script from "./v-about.vue?vue&type=script&lang=js"
export * from "./v-about.vue?vue&type=script&lang=js"
import style0 from "./v-about.vue?vue&type=style&index=0&id=41ce345a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ce345a",
  null
  
)

export default component.exports