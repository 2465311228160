<template>
    <div class="v-header-logo">
        <img src="@/assets/icons/logo/logo.jpg" alt="logo" class="v-header_bkock_logo_img">
    </div>
</template>
<script>
export default {
    name: 'vHeaderLogo'
}
</script>
<style scoped lang="scss">
  .v-header-logo{
    img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
  }  
.v-header_block_logo_img{
    animation: flip;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}
.v-header_block_logo_img{
    animation: flip;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}
</style>