<template>
   <!-- Yandex.RTB R-A-11783043-4 -->
<div id="yandex_rtb_R-A-11783043-4"></div>
</template>
    
<script>
export default {
    name:'v-Yan2'
}
</script>
    
<script>
window.yaContextCb.push(() => {
    Ya.Context.AdvManager.render({
        "blockId": "R-A-11783043-4",
        "renderTo": "yandex_rtb_R-A-11783043-4"
    })
})
</script>