<template>
    <div class="v-derma">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Дерматология</h1> 
                <div class="v-modul-subtitle">Дерматология является одной из самых важных областей ветеринарии, так как большинство домашних животных подвержены целому спектру кожных заболеваний. Дерматология занимается вопросами здоровья кожи, когтей, шерсти, аллергических реакций, паразитарных поражений и т.п. Состояние кожи, шерсти и когтей является важным индикатором здоровья для животных, и потому пренебрегать визитами к дерматологу не стоит.
                    <picture>
                        <source srcset="../../../assets/images/derma/derma1.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/derma/derma1.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/derma/derma1.jpg" class="card-img-top r-img" alt="Аллергия у животного">
                    </picture>
                    <h2>У нас вы можете получить помощь при аллергических заболеваниях кожи, а также связанных с нарушением обмена веществ, паразитарных заболеваниях кожи (демодекоз  у собак, саркоптоз, псороптоз, блошиная инвазия). Мы делаем микроскопию соскобов кожи, бакпосев с подтитровкой к антибиотикам, микологический посев.</h2>
                    <h1>Аллергия </h1>
                    <h2>Самыми типичными общими признаками аллергии являются:</h2>
                    <ul>
                        <li>зуд</li>
                        <li>эритема (расширение сосудов в комбинации с покраснением кожи, спровоцированном расширением)</li>
                        <li>склонность к вторичным инфекциям.</li>
                    </ul>
                    <h2>При этом у животного может наблюдаться лишь один из вышеперечисленных признаков, либо комбинация из любых двух явлений, а так же и все три сразу.</h2>
                    <h2>Не всегда можно сразу понять, что у животного зуд. Иногда хозяин может посчитать, что животное просто очень чистоплотное, потому и вылизывается так часто.</h2>
                    <h2>Определить, что у животного зуд, можно по таким признакам:</h2>
                    <ul>
                        <li>сильный, порой нестерпимый зуд</li>
                        <li>грызет себя</li>
                        <li>лижет лапы</li>
                        <li>трясет головой</li>
                        <li>трет морду</li>
                        <li>катается на спине</li>
                        <li>самоиндуцированная алопеция (чрезмерный груминг)</li>
                        <li>выпадение шерсти</li>
                        <li>излишняя пигментация в местах обострения;</li>
                        <li>сухость и шелушение кожных покровов</li>
                    </ul>
                    <h2>Три самые распространенные виды аллергий это:</h2>
                    <ul>
                        <li>аллергия, связанная с укусами блох</li>
                        <li>пищевая аллергия</li>
                        <li>атопический дерматит</li>
                    </ul>
                    <picture>
                        <source srcset="../../../assets/images/derma/derma2.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/derma/derma2.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/derma/derma2.jpg" class="card-img-top r-img" alt="Аллергия">
                    </picture>
                    <h6>Локализация симптомов при пищевой аллергии и атопическом дерматите</h6>
                    <h1>Аллергия на укусы блох</h1>
                    <h2>Симптомы</h2>
                    <ul>
                        <li>Блошиная инвазия.Зуд может быть интенсивным, или отсутствовать вовсе, что зависит от количества блох. При этом на животном можно увидеть блох, и причина зуда не вызывает сомнений.</li>
                        <li>Зуд спровоцирован гиперчувствительностью к слюне блох. При этом, вне зависимости от количества блох, зуд будет очень интенсивным. Блох на животном можно и не выявить, что заставит усомниться в точности диагноза. Однако при этом следует вспомнить, что взрослые блохи, которых можно обнаружить на животном, составляют лишь 5% популяции блох в квартире. Остальные 95 живут в коврах, мебели и других предметах обстановки.</li>
                    </ul>
                    <h2>Блошиная аллергия может проявляться не у всех животных в доме. В теплое время года встречается чаще, однако совершенно не исключена и зимой.</h2>
                    <picture>
                        <source srcset="../../../assets/images/derma/derma3.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/derma/derma3.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/derma/derma3.jpg" class="card-img-top r-img" alt="Блошиная аллергия">
                    </picture>
                    <h6>Блошиный дерматит</h6>
                    <h1>Пищевая аллергия </h1>
                    <h2>Симптомы:</h2>
                    <ul>
                        <li>нежелательная пищевая реакция – клинически аномальный ответ на компоненты пищи</li>
                        <li>гиперчувствительность/аллергия – повышенный ответ иммунной системы на аллерген</li>
                        <li>пищевая непереносимость – аномальный физиологический ответ на компоненты пищи, развитие фармакологических, метаболических реакций. Не связано с аллергией.</li>
                        <li>токсические реакции – дозозависимая реакция на токсины</li>
                    </ul>
                    <h1>Атопический дерматит</h1>
                    <h2>Атопический дерматит – это неадекватный ответ иммунной системы (немедленная аллергическая реакция), сформированный вследствие унаследованной генетической склонности и вызванный определенными факторами окружающей среды (домашняя пыль, плесень, чешуйки кожи человека, другие животные, пыльца растений).</h2>
                    <h2>Профилактика дерматологических заболеваний:</h2>
                    <ul>
                        <li>применение противопаразитарных средств;</li>
                        <li>организация правильного ухода за питомцем, а также правильного режима питания;</li>
                        <li>исключение потенциально опасных контактов (с бездомными, заболевшими животными, носителями паразитов и т.п.).</li>
                    </ul>
                    <h2>Состояние кожи, шерсти, ушей нужно контролировать, периодически осматривая питомца.</h2>
                </div>                                                       
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>           
        </div> 
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'

export default {
    name: 'vDerma',
    components: {
        vSide,
        vContacts
    },
    mixins: [resize],
}

</script>

<style scoped lang="scss">

 @import '@/assets/css/modul.scss';

</style>