<template>
    <div class="v-stom">
        <div class="container">
           <div class="v-modul-block">
               <div class="v-modul-block-left">
                <h1 class="v-modul_title">Стоматология</h1> 
                <div class="v-modul-subtitle">
                    <h1>Болезни полости рта и зубов.</h1>
                    <h2>Более 70% животных имеют дентальную проблему, которая в большинстве случаев определяется только при осмотре ветеринарным врачом. На начальной стадии развития такие заболевания можно легко устранить, но хозяева почему-то оттягивают посещение специалиста до момента, когда случай можно будет назвать «запущенным».</h2>
                    <h2>Симптомы, при которых следует обратиться к ветеринарному стоматологу:</h2>
                    <ul>
                        <li>неприятных запах из ротовой полости</li>
                        <li>отказ от корма</li>
                        <li>прием пищи, сопровождающийся несвойственными для вашего животного звуками</li>
                        <li>покраснение и кровоточивость десен</li>
                        <li>отложение зубного камня</li>
                        <li>переломы зуба и сколы эмали</li>
                        <li>нарушение неподвижности зуба</li>
                        <li>травматический прикус</li>
                        <li>отечность в области ротовой полости и др.</li>
                    </ul>
                   <h1>Причины развития патологий</h1>
                   <h2>Основными причинами болезни ротовой полости являются:</h2>
                   <ul>
                    <li>несоблюдение гигиенических норм по уходу за ротовой полостью и зубами</li>
                    <li>травмы и механические повреждения мягких тканей (десен, губ, языка, щек) и зубов</li>
                    <li>химические повреждения слизистой ротовой полости</li>
                    <li>аллергические реакции на продукты питания</li>
                    <li>нарушения обмена веществ</li>
                    <li>диабет</li>
                    <li>болезни печени, почек и желудочно-кишечного тракта</li>
                   </ul>
                   <h2>Наиболее частые проблемы: воспаление десен, зубной камень, пародонтоз, невыпадение молочных зубов. В нашей клинике Вы сможете получить квалифицированную ветеринарную помощь по этим вопросам.</h2>
                   <h2>Провести санацию ротовой полости, снятие зубного камня у собак и кошек, удалить больные зубы у собаки и кошки, удалить молочные зубы у щенка, получить консультацию по профилактике болезней десен и зубов у собак и кошек.</h2>
                   <h2>Если вы заметили запах изо рта у собаки или кошки, желтый налет на зубах, слюнотечение, затрудненный прием корма, Вам необходимо показать животное ветеринару!</h2>
                   <h2>К заболеваниям пародонта относят гингивит, пародонтит, пародонтоз, опухолевые и опухолеподобные поражения пародонта.</h2>
                   <h1>Гингивит</h1>
                   <h2>Гингивит - это воспаление десен без нарушения целостности зубодесневого соединения. Если заболевание не лечить, оно может перейти в пародонтит и гингивостоматит. </h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom4.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom4.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom4.jpg" class="card-img-top r-img" alt="Стоматология">
                    </picture>
                    <h2>Гингивит, как правило, возникает из-за скопления микробного налёта на зубах, в результате несоблюдения гигиены ротовой полости. У кошек часто бывает «аллергия» на зубной налет, вследствие чего  появляется хронический гингивостоматит.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom4_1.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom4_1.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom4_1.jpg" class="card-img-top r-img" alt="Зубы, гингивит">
                    </picture>
                    <h1>Пародонтит</h1>
                    <h2> Пародонтит - это воспалительный процесс, в который вовлекаются все структуры пародонта. Он характеризуется разрушением зубодесневого соединения, а также прогрессирует деструкция («растворение, разрушение») альвеолярных отростков челюстных костей.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom5.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom5.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom5.jpg" class="card-img-top r-img" alt="Пародонтит">
                    </picture>
                    <h2>Фактически гингивит и пародонтит — это две взаимосвязанные формы заболевания, поскольку воспалительный процесс возникает сначала в тканях десны, и постепенно в него вовлекаются подлежащие структуры пародонта: связка зуба и альвеолярная кость.</h2>
                    <h2>Пародонтоз - это дистрофическое поражение пародонта. При данном заболевании зубы не шатаются, но есть значительная потеря десневой ткани.</h2>
                    <h1>Гингивостоматит</h1>
                    <h2>Гингивостоматит также может быть проявлением некоторых инфекционных заболеваний кошек, таких как панлейкопения, кошачий иммунодефицит, калицивирусная, коронавирусная, герпетическая и другие инфекции.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom6.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom6.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom6.jpg" class="card-img-top r-img" alt="Гингивостоматит">
                    </picture>
                    <h1>Одонтокластическая резорбция зубов</h1>
                    <h2>Одонтокластическая резорбция зубов - заболевание, при котором происходит разрушение зубов (корня и коронки – видимой белой части), которая приводит к полной потере пораженного зуба. Этиология данного заболевания неизвестна. Консервативного лечения не существует. Такие зубы подлежат экстракции (удалению).</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom7.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom7.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom7.jpg" class="card-img-top r-img" alt="Одонтокластическая резорбция зубов">
                    </picture>
                    <h1>Фрактура (перелом) зуба</h1>
                    <h2>Фрактура (перелом) зуба – это откол части или всей коронки зуба. Чаще всего причиной является травма. Такие зубы лечатся: проводится эндодонтическое лечение (пломбировка канала зуба) с последующим наложением пломбы, с/без укрепления металлической/металлокерамической коронкой.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom8.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom8.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom8.jpg" class="card-img-top r-img" alt="Фрактура (перелом) зуба<">
                    </picture>
                    <h1>Пародонтомы</h1>
                    <h2>Пародонтомы - опухолевые и опухолеподобные поражения пародонта относятся к числу заболеваний, которые трудно прогнозировать, так как они развиваются только у кошек, имеющих склонность к данному процессу, а именно индивидуальные особенности организма, в том числе и гормональные сдвиги. Породной предрасположенности к таким заболеваниям нет.</h2>
                    <picture>
                        <source srcset="../../../assets/images/teeth/stom9.webp" class="card-img-top r-img" type="image/webp">
                        <source srcset="../../../assets/images/teeth/stom9.jpg" class="card-img-top r-img" type="image/jpeg"> 
                        <img src="../../../assets/images/teeth/stom9.jpg" class="card-img-top r-img" alt="Пародонтомы">
                    </picture>
                    <h2>В любом случае, точный диагноз можно определить только при осмотре ротовой полости, различных цитологических и гистологических исследованиях.</h2>
                </div>                                                       
                </div>               
                <v-contacts v-if="small"/>             
                <v-side v-else/>
            </div>             
        </div> 
    </div>
</template>

<script>
import resize from  '@/mixins/resize'
import vSide from '@/components/side/v-side.vue'
import vContacts from '@/components/contacts/v-contacts.vue'
export default {
  name: 'vStom',
  components: {
        vSide,
        vContacts,
    },
    mixins: [resize]
}
</script>
<style scoped lang="scss">
    @import '@/assets/css/modul.scss';
</style>